/* eslint-disable */

import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import useAuth from "contexts/useAuth";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

export function SidebarLinks(props: { routes: RoutesType[] }) {
  //   Chakra color mode
  const [openNames, setOpenNames] = useState<string[]>([]);

  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  const { userData } = useAuth();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  const childrenActive = (children: RoutesType[]) => {
    return children.some((child) => activeRoute(child.path.toLowerCase()));
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes: RoutesType[], subRoute = false) => {
    return routes
      .filter(({ hideOnSideBar }) => !hideOnSideBar)
      .filter(({ isMaster }) => (isMaster ? !userData?.isCustomerAdmin : true))
      .map((route: RoutesType, index: number) => {
        if (route.children?.length) {
          return (
            <Box>
              <HStack
                py="5px"
                ps="10px"
                cursor={"pointer"}
                onClick={() => {
                  if (openNames.includes(route.name)) {
                    setOpenNames(
                      openNames.filter((name) => name !== route.name)
                    );
                  } else {
                    setOpenNames([...openNames, route.name]);
                  }
                }}
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    me="18px"
                    alignItems={"center"}
                    display={"flex"}
                    height={"22px"}
                    color={
                      childrenActive(route.children) ? brandColor : textColor
                    }
                  >
                    {route.icon}
                  </Box>
                  <Text
                    me="auto"
                    fontWeight={
                      !openNames.includes(route.name) &&
                      childrenActive(route.children)
                        ? "bold"
                        : "normal"
                    }
                    color={
                      !openNames.includes(route.name) &&
                      childrenActive(route.children)
                        ? activeColor
                        : textColor
                    }
                  >
                    {route.name}
                  </Text>
                </Flex>
                <Text color={textColor} fontWeight="normal">
                  {openNames.includes(route.name) ? (
                    <IoIosArrowDown color={textColor} />
                  ) : (
                    <IoIosArrowForward color={textColor} />
                  )}
                </Text>
                <Box
                  h="36px"
                  w="4px"
                  bg={
                    childrenActive(route.children) ? brandColor : "transparent"
                  }
                  borderRadius="5px"
                />
              </HStack>
              {openNames.includes(route.name) &&
                createLinks(route.children, true)}
            </Box>
          );
        }

        if (!route.path) {
          return "";
        }

        if (
          route.layout === "/admin" ||
          route.layout === "/auth" ||
          route.layout === "/rtl"
        ) {
          return (
            <NavLink key={index} to={route.layout + route.path}>
              {route.icon ? (
                <Box>
                  <HStack py="5px" ps="10px">
                    <Flex
                      w="100%"
                      alignItems="center"
                      justifyContent="center"
                      pl={subRoute ? "20px" : "0px"}
                    >
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me="18px"
                        alignItems={"center"}
                        display={"flex"}
                        height={"22px"}
                      >
                        {route.icon}
                      </Box>
                      <Text
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                      >
                        {route.name}
                      </Text>
                      <Text color={textColor} fontWeight="normal">
                        <IoIosArrowForward color={textColor} />
                      </Text>
                    </Flex>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </NavLink>
          );
        }
      });
  };
  //  BRAND
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
