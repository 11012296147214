import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import React from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import FormLabelCustom from "./FormLabel";
import { currencyToNumber } from "utils/number";

function currencyFormatter(value: string) {
  if (!value) value = "0";

  value = String(value).replace(/\D/g, "");

  const amount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(+value / 100);

  return `${amount}`;
}

const InputNumber: React.FC<{
  control: Control<any>;
  label: string;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions<any>;
}> = ({ control, label, rules, name, placeholder }) => {
  const [inputValue, setInputValue] = React.useState("0");

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error, isDirty },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom
            name={label}
            label={label}
            props={{}}
          ></FormLabelCustom>
          <Input
            name={name}
            value={currencyFormatter(
              isDirty ? inputValue : (+value || 0).toFixed(2)
            )}
            size={"md"}
            onFocus={(e) => {
              e.target.setSelectionRange(
                e.target.value.length,
                e.target.value.length
              );
            }}
            onPasteCapture={(e) => {
              e.preventDefault();

              let value = e.clipboardData.getData("Text");
              if (
                !String(value).includes(".") &&
                !String(value).includes(",")
              ) {
                value = `${value}00`;
              }
              const valueFormated = currencyFormatter(value);

              const valueNumber = currencyToNumber(valueFormated);
              setInputValue(valueNumber.toFixed(2));
              onChange(valueNumber);
            }}
            onChange={(e) => {
              const value = e.target.value;
              const valueFormated = currencyFormatter(value);

              const valueNumber = currencyToNumber(valueFormated);
              setInputValue(valueNumber.toFixed(2));

              onChange(valueNumber);
            }}
            type={"text"}
            fontSize={"sm"}
            ms={{ base: "0px", md: "0px" }}
            placeholder={placeholder}
          />

          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default InputNumber;
