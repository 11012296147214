// Chakra imports
import {
  Badge,
  Box,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Tooltip,
  useToast,
} from "@chakra-ui/react";

import { useMemo } from "react";
import {
  changeStatusIntegrations,
  findAllIntegrations,
} from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import CreateIntegration from "./components/CreateIntegration";
import { MdBlock } from "react-icons/md";
import { CheckIcon } from "@chakra-ui/icons";
import { queryClient } from "index";
import SeeIntegration, {
  IntegrationResponse,
} from "./components/SeeIntegration";
import SicoobIntegration from "./components/SicoobIntegration";
import useAuth, { UserPermissions } from "contexts/useAuth";
import EyeMobileIntegration from "./components/EyeMobileIntegration";
import { FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export const StatusRender = (status: number) => {
  if (!status) return <Badge colorScheme="orange">Inativo</Badge>;
  if (status) return <Badge colorScheme="green">Ativo</Badge>;
};

export enum IntegrationType {
  PAG_SEGURO = "Pag Seguro",
  PAG_SEGURO_EDI = "Pag Seguro Conciliação",
  PAG_SEGURO_PIX = "Pag Seguro Pix",
  ASSAS = "Assas",
  SULCREDI = "Sulcredi",
  SULCREDI_CASHOUT = "Sulcredi Cashout",
  TICKETNOW = "Ticket Now",
  SICOOB = "Sicoob",
  SICREDI = "Sicredi",
  MERCADO_PAGO = "Mercado Pago",
  CIELO = "Cielo",
  GETNET = "Getnet",
  EYE_MOBILE = "Eye Mobile",
}

export default function Integrations() {
  const toast = useToast();
  const { hasPermission } = useAuth();
  const hasPerm = hasPermission(UserPermissions.INTEGRATIONS_WRITE);
  const router = useHistory();

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome da Integração",
          accessor: "name",
        },
        {
          Header: "Usuário",
          accessor: "user",
        },
        {
          Header: "Status",
          accessor: "active",
          Cell: ({ value }) => {
            return StatusRender(value);
          },
        },
        {
          Header: "Tipo",
          accessor: "type",
          Cell: ({ value }) => {
            return IntegrationType[value as keyof typeof IntegrationType];
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: IntegrationResponse) => (
    <ButtonGroup>
      <SeeIntegration data={row} disabled={!hasPerm} />
      {hasPerm && (
        <Tooltip label="Dados recebidos">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            size="sm"
            variant="outline"
            as="a"
            _hover={{ cursor: "pointer" }}
            icon={<FaEye />}
            onClick={() => router.push(`/admin/integration/${row.id}/last`)}
          ></IconButton>
        </Tooltip>
      )}
      {hasPerm && <EyeMobileIntegration data={row} />}
      {hasPerm && (
        <Tooltip label="Desabilitar/Ativar Integração">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            size="sm"
            variant="outline"
            icon={row.active ? <MdBlock /> : <CheckIcon />}
            onClick={() =>
              changeStatusIntegrations({
                id: row.id,
                status: !row.active,
              })
                .then(() => {
                  queryClient.refetchQueries({
                    queryKey: ["findAllIntegrations"],
                    type: "active",
                  });
                })
                .catch((err) => {
                  toast({
                    title: "Erro ao alterar status da integração",
                    description: err.message,
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                })
            }
          />
        </Tooltip>
      )}
      {hasPerm && <SicoobIntegration data={row} />}
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          filterable={["companyId"]}
          right={<>{hasPerm && <CreateIntegration />}</>}
          queryFn={findAllIntegrations}
          queryKey="findAllIntegrations"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
