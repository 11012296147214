import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import { useTwoFaHelper } from "contexts/TwoFaCheckContext";
import React from "react";
import { useForm } from "react-hook-form";
import {
  findAllClients,
  transferAmountAnotherCustomer,
} from "services/api.service";
export interface TransferCustomerForm {
  amount: number;
  customer: SelectOptionType;
  description: string;
}

const TransferCustomerAmount: React.FC<{
  customerId: string;
  type: "balance" | "balancePending" | "balanceLocked";
  maxAmount: number;
  refetch: () => void;
}> = ({ customerId, refetch, maxAmount, type }) => {
  const { onOpen, isOpen, onClose: onCloseModal } = useDisclosure();
  const { requestTwoFa } = useTwoFaHelper();
  const [loading, setLoading] = React.useState(false);

  const toast = useToast();

  const { control, reset, handleSubmit } = useForm<TransferCustomerForm>({
    defaultValues: {
      amount: 0,
    },
  });

  const onClose = () => {
    onCloseModal();
    reset();
  };

  const onSubmit = async (dataRequest: TransferCustomerForm) => {
    const twofaData = await requestTwoFa();
    if (!twofaData?.id) {
      setLoading(false);
      toast({
        title: "Erro",
        description: "Autenticação não informada",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    let request = transferAmountAnotherCustomer(customerId, twofaData.id, {
      amount: dataRequest.amount,
      description: dataRequest.description,
      receiverCustomerId: dataRequest.customer.value,
      type,
    });
    setLoading(true);
    request
      .then(() => {
        refetch();
        toast({
          title: "Succeso",
          description: `Valor transferido com Sucesso`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: "Error",
          description:
            err?.response?.data?.message || "Erro ao transferir valor",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {maxAmount > 0 && (
        <CustomButton colorScheme="brand" onClick={onOpen} variant="link">
          Transferir
        </CustomButton>
      )}
      {isOpen && (
        <Portal>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Transferir Valor para outra conta</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <VStack spacing={5}>
                    <FormRemoteSelectInput
                      control={control}
                      label="Cliente"
                      loadDataFn={({ value, cb }) =>
                        findAllClients({ filter: value }).then((retorno) => {
                          if (retorno?.registers?.length > 0) {
                            cb(
                              retorno.registers?.map((d: any) => ({
                                label: d.name,
                                value: d.id,
                              }))
                            );
                          } else {
                            cb([]);
                          }
                        })
                      }
                      name="customer"
                    />
                    <InputForm
                      control={control}
                      label="Descrição"
                      name="description"
                      type="text"
                    />
                    <InputNumber
                      control={control}
                      name="amount"
                      label="Valor de tranferência"
                      placeholder="R$ 0,00"
                      rules={{
                        required: "Campo obrigatório",
                        max: {
                          value: maxAmount,
                          message: `Valor máximo é de R$ ${maxAmount}`,
                        },
                      }}
                    />

                    <Button
                      isLoading={loading}
                      type="submit"
                      variant={"outline"}
                      w={`100%`}
                      color={"green"}
                    >
                      Salvar
                    </Button>
                  </VStack>
                </form>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default TransferCustomerAmount;
