import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  useDisclosure,
  Progress,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import React, { useEffect } from "react";
import { MdOutlineCached } from "react-icons/md";
import { antecipateTransactionCustomer } from "services/api.service";

const AntecipateTransactions: React.FC<{
  ids: string[];
  refetch: () => void;
}> = ({ ids, refetch }) => {
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const toast = useToast();
  const [updates, setUpdates] = React.useState(0);

  const save = async () => {
    setLoading(true);

    for await (const id of ids) {
      await antecipateTransactionCustomer({
        movementId: id,
      })
        .catch((err) => {
          toast({
            title: "Erro",
            description:
              err?.response?.data?.message || "Erro ao antecipar movimento",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setUpdates((prev) => prev + 1);
        });
    }

    toast({
      title: "Sucesso",
      description: "Movimentos antecipados com sucesso",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    setDone(true);
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      setUpdates(0);
      setDone(false);
    }
  }, [isOpen]);

  const percent = (updates / ids.length) * 100;

  return (
    <>
      <CustomButton
        isDisabled={ids.length === 0}
        colorScheme="green"
        leftIcon={<MdOutlineCached />}
        onClick={onOpen}
      >
        Antecipar Vendas
      </CustomButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Antecipar {ids.length} venda</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading || done ? (
              <Progress size="xs" w={"100%"} value={percent} />
            ) : (
              <></>
            )}
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
              <Button
                colorScheme="red"
                isLoading={loading}
                onClick={() => {
                  onClose();
                  refetch();
                }}
              >
                {done ? "Fechar" : "Cancelar"}
              </Button>
              <Button
                colorScheme="green"
                isLoading={loading}
                isDisabled={done}
                onClick={save}
              >
                Antecipar
              </Button>
            </HStack>
          </ModalFooter>
          ;
        </ModalContent>
      </Modal>
    </>
  );
};

export default AntecipateTransactions;
