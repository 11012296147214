// Chakra imports
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  SimpleGrid,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import { useMemo, useState } from "react";
import { findAllTerminals } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { FaExchangeAlt, FaTrashAlt } from "react-icons/fa";
import ModalSelectMaquineta from "./components/ModalSelectMaquineta";
import { queryClient } from "index";
import ModalDeleteMaquineta from "./components/ModalDeleteMaquineta";
import ModalAddTerminal from "./components/ModalAddMaquineta";
import { AddIcon } from "@chakra-ui/icons";
import ChangeManyTerminals from "./components/AlterarMuitasMaquinetas";
import useAuth, { UserPermissions } from "contexts/useAuth";
import ModalEditMaquineta from "./components/ModalEditMaquineta";

export default function Maquinetas() {
  const [alterMaquineta, setAlterMaquineta] = useState<any>(null);
  const [removeMaquineta, setRemoveMaquineta] = useState<any>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [rows, setSelectedRows] = useState<string[]>([]);
  const { hasPermission } = useAuth();
  const writePermission = hasPermission(UserPermissions.MAQUINETAS_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome",
          accessor: "name",
        },
        {
          Header: "Serial",
          accessor: "number",
        },
        {
          Header: "Id Terminal",
          accessor: "terminalId",
        },
        {
          Header: "Cliente",
          accessor: "MaquinetasCustomers[0].Customer.name",
          disableSortBy: true,
        },
        {
          Header: "Empresa",
          accessor: "Company.name",
          disableSortBy: true,
        },
        {
          accessor: "createdAt",
          Header: "Data de criação",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <ButtonGroup>
      {writePermission && (
        <Tooltip label="Alterar vínculo" aria-label="Visualizar">
          <IconButton
            aria-label="Alterar"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<FaExchangeAlt />}
            onClick={() => setAlterMaquineta(row)}
          />
        </Tooltip>
      )}
      {writePermission && (
        <ModalEditMaquineta
          data={row}
          onClose={() => {
            setAlterMaquineta(null);
            refetch();
          }}
        />
      )}
      {writePermission && (
        <Tooltip label="Deletar maquineta" aria-label="Visualizar">
          <IconButton
            aria-label="Alterar"
            rounded="md"
            size="sm"
            variant="outline"
            icon={<FaTrashAlt />}
            onClick={() => setRemoveMaquineta(row)}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  );

  const refetch = () => {
    queryClient.refetchQueries({
      queryKey: ["findAllTerminals"],
      type: "active",
    });
  };

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      {isOpen && writePermission && (
        <ModalAddTerminal
          onClose={() => {
            onClose();
            refetch();
          }}
          isOpen={isOpen}
        />
      )}
      {alterMaquineta && writePermission && (
        <ModalSelectMaquineta
          data={alterMaquineta}
          onClose={() => {
            setAlterMaquineta(null);
            refetch();
          }}
        />
      )}
      {removeMaquineta && writePermission && (
        <ModalDeleteMaquineta
          data={removeMaquineta}
          onClose={() => {
            setRemoveMaquineta(null);
            refetch();
          }}
        />
      )}
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          queryFn={findAllTerminals}
          queryKey="findAllTerminals"
          filterable={["customerId"]}
          actions={actions}
          enableSelect={writePermission}
          setSelectedRows={setSelectedRows}
          right={
            <ButtonGroup>
              {writePermission && <ChangeManyTerminals ids={rows} />}
              {writePermission && (
                <Button
                  onClick={onOpen}
                  size="sm"
                  rounded="sm"
                  variant="outline"
                  colorScheme="brand"
                >
                  <AddIcon></AddIcon>
                  <Text ml={2}>Adicionar</Text>
                </Button>
              )}
            </ButtonGroup>
          }
        />
      </SimpleGrid>
    </Box>
  );
}
