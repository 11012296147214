// Chakra imports
import {
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  HStack,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import { cancelMovement, getMovement } from "services/api.service";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { DateTime } from "luxon";
import { maskCurrency } from "utils/number";
import {
  Balance,
  MovementsType,
  MovementsTypeDescription,
} from "types/Movement";
import RelateClient from "./components/RelateClient";
import { StatusRender } from ".";
import { IntegrationType } from "../integrations";
import LinkToPage from "components/LinkToPage";
import useAuth, { UserPermissions } from "contexts/useAuth";
import { BalanceTypeDescription } from "../balances/balances-pending";
import CustomButton from "components/CustomButton";

const BalancesForMovement: React.FC<{
  balances?: Balance[];
}> = ({ balances }) => {
  return (
    <TableContainer>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>Tipo</Th>
            <Th>Cliente</Th>
            <Th isNumeric>Valor</Th>
            <Th isNumeric>Valor estornado</Th>
            <Th>Data de Liberação</Th>
          </Tr>
        </Thead>
        <Tbody>
          {balances?.map((item) => (
            <Tr key={item.id}>
              <Td>{BalanceTypeDescription[item.type]}</Td>
              <Td>
                <LinkToPage
                  href={`/#/admin/customer/${item?.Customer.id}`}
                  name={item.Customer?.name}
                />
              </Td>
              <Td isNumeric> {maskCurrency(item?.amount)}</Td>
              <Td isNumeric>
                {item?.amountRefunded
                  ? maskCurrency(item?.amountRefunded)
                  : "---"}
              </Td>
              <Td>
                <HStack>
                  <Box mr={2}>
                    {item?.releasedOn
                      ? DateTime.fromISO(item?.releasedOn).toFormat(
                          !item?.deletedAt && !item?.released
                            ? "dd/MM/yyyy"
                            : "dd/MM/yyyy HH:mm"
                        )
                      : "---"}
                  </Box>
                  {item?.deletedAt ? (
                    <Badge colorScheme="red">CANCELADO</Badge>
                  ) : item?.released ? (
                    <Badge colorScheme="green">LIBERADO</Badge>
                  ) : (
                    <Badge colorScheme="orange">AGUARDANDO</Badge>
                  )}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default function Movement() {
  const { id } = useParams<{ id: string }>();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { hasPermission } = useAuth();
  const hasPerm = hasPermission(UserPermissions.MOVEMENTS_WRITE);

  const { data, refetch } = useQuery(["getMovement", id], () =>
    getMovement(id)
  );

  const handleCancel = () => {
    if (window.confirm("Deseja cancelar o movimento?")) {
      cancelMovement(id).then(() => {
        refetch();
      });
    }
  };

  const handleRelatedClient = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const itens = useMemo(
    () => [
      {
        title: "Id da transação",
        content: data?.transactionCode,
      },
      ...(data?.chargeId
        ? [
            {
              title: "Id Cobrança",
              content: (
                <LinkToPage
                  href={`/#/admin/charge/${data?.chargeId}`}
                  name={data?.chargeId}
                />
              ),
            },
          ]
        : []),
      ...(data?.integrationType
        ? [
            {
              title: "Tipo da Integração",
              content: IntegrationType[data?.integrationType],
            },
          ]
        : []),
      {
        title: "Nome do cliente",
        content: data?.Customer ? (
          <LinkToPage
            href={`/#/admin/customer/${data?.Customer.id}`}
            name={data.Customer?.name}
          />
        ) : (
          hasPerm && (
            <Button
              onClick={handleRelatedClient}
              color="red"
              variant="solid"
              size="sm"
            >
              Relacionar
            </Button>
          )
        ),
      },
      {
        title: "Data da transação",
        content: DateTime.fromISO(data?.orderAt).toFormat(
          `dd/MM/yyyy - HH:mm:ss`
        ),
      },
      {
        title: "Valor Bruto",
        content: maskCurrency(data?.amount),
      },
      {
        title: "Valor das Taxas",
        content: `${maskCurrency(data?.taxes)} (${
          +data?.feePercent ||
          (100 - (+data?.amountLiquid / +data?.amount) * 100).toFixed(2)
        }%)`,
      },
      {
        title: "Valor Liquído",
        content: maskCurrency(data?.amountLiquid),
      },
      ...(data?.amountOriginalLiquid
        ? [
            {
              title: "Valor Liquído Gateway",
              content: maskCurrency(data?.amountOriginalLiquid),
            },
          ]
        : []),
      {
        title: "Tipo",
        content: MovementsTypeDescription[data?.type],
      },
      ...([MovementsType.CREDIT_CARD, MovementsType.DEBIT_CARD].includes(
        data?.type
      )
        ? [
            ...(data?.type === MovementsType.CREDIT_CARD
              ? [
                  {
                    title: "Quantidade de parcelas",
                    content: `${data?.installmentCount}x`,
                  },
                ]
              : []),

            { title: "Bandeira", content: data?.flagCard },
            {
              title: "Cartão",
              content: data?.holderName
                ? "**** **** **** " + data?.holderName
                : "---",
            },
          ]
        : []),

      {
        title: "Status",
        content: data?.status && StatusRender(data.status),
      },
      ...(data?.Maquinetas?.number
        ? [
            {
              title: "Id do Terminal",
              content:
                data?.Maquinetas?.number + " - " + data?.Maquinetas?.name,
            },
          ]
        : []),
      {
        title: "Tipo operação",
        content: "Pagamento",
      },
    ],
    [data, handleRelatedClient, hasPerm]
  );

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        {isOpen && (
          <RelateClient
            movement={data}
            onClose={() => {
              onClose();
              refetch();
            }}
          />
        )}
        <Card px={3}>
          <CardHeader p={2} mt={3}>
            <Box fontSize="2xl" fontWeight="semibold">
              Detalhes da transação
            </Box>
            <small>Transação: #{data?.id}</small>
          </CardHeader>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}
            spacing={5}
          >
            {itens.map((item) => (
              <Box key={item.title} boxShadow="none" p="2" px={2}>
                <Box fontSize="md" fontWeight="semibold" color="gray.600">
                  {item.title}
                </Box>
                <Box fontSize="md">{item.content}</Box>
              </Box>
            ))}
          </SimpleGrid>
          <Divider color="gray.300" />

          <Box mb={4}>
            <HStack
              mb={2}
              p="1"
              px={2}
              fontSize="lg"
              fontWeight="semibold"
              color="gray.600"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Text>Detalhes dos saldos</Text>
              {hasPerm && (
                <>
                  <CustomButton
                    onClick={handleRelatedClient}
                    colorScheme="brand"
                  >
                    Alterar Cliente
                  </CustomButton>
                  <CustomButton onClick={handleCancel} colorScheme="red">
                    Cancelar movimento
                  </CustomButton>
                </>
              )}
            </HStack>

            <BalancesForMovement balances={data?.balance} />
          </Box>
        </Card>
      </SimpleGrid>
    </Box>
  );
}
