import { validCnpj, validCpf } from "services/validDocument";
import { mask } from "./mask";

export function onlyAlphaNum(input?: string): string {
  if (!input) return ``;

  return String(input).replace(/[^\w]/g, ``);
}
export function onlyNumbers(input?: string | number): string {
  if (!input) return ``;

  return String(input).replace(/[^\d]/g, ``);
}

export const maskPixKey = (
  value?: string
): { type: string; masked: string; raw: string } => {
  if (!value) return { type: `INVALID`, masked: ``, raw: `` };
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const normalizedValue = value.trim().toLowerCase();

  if (normalizedValue.match(/^[0-9]{11}$/)) {
    if (validCpf(normalizedValue)) {
      return {
        type: `CPF`,
        masked: mask(normalizedValue, `999.999.999-99`),
        raw: normalizedValue.trim(),
      };
    }
    return {
      type: `PHONE`,
      masked: mask(normalizedValue, `(99) 99999-9999`),
      raw: `+55${onlyNumbers(normalizedValue.trim())}`,
    };
  }
  if (normalizedValue.match(/^[0-9]{10}$/)) {
    return {
      type: `PHONE`,
      masked: mask(normalizedValue, `(99) 9999-9999`),
      raw: `+55${onlyNumbers(normalizedValue.trim())}`,
    };
  }
  if (normalizedValue.match(/^\+55\d{10,11}$/)) {
    const phoneNumber = normalizedValue.replace(`+55`, ``);
    return {
      type: `PHONE`,
      masked: mask(
        phoneNumber,
        phoneNumber.length > 10 ? `(99) 99999-9999` : `(99) 9999-9999`
      ),
      raw: normalizedValue,
    };
  }
  if (normalizedValue.match(/^[0-9]{14}$/)) {
    return {
      type: `CNPJ`,
      masked: mask(normalizedValue, `99.999.999/9999-99`),
      raw: normalizedValue.trim(),
    };
  }
  if (
    normalizedValue?.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    )
  ) {
    return {
      type: `EMAIL`,
      masked: normalizedValue,
      raw: normalizedValue.trim().toLowerCase(),
    };
  }
  if (
    normalizedValue?.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
    )
  ) {
    return {
      type: `EVP`,
      masked: normalizedValue,
      raw: normalizedValue.trim().toLowerCase(),
    };
  }
  return { type: `INVALID`, masked: normalizedValue, raw: normalizedValue };
};

export const unMaskPixKey = (
  value?: string
): { type: string; masked: string; raw: string } => {
  if (!value) return { type: `INVALID`, masked: ``, raw: `` };

  const normalizedValue = value.trim().toLowerCase();
  const unMaskedValue = onlyAlphaNum(value).trim();
  if (
    normalizedValue.match(
      /(^\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$)/
    )
  ) {
    if (unMaskedValue.length === 11 && validCpf(unMaskedValue)) {
      return {
        type: `CPF`,
        masked: normalizedValue,
        raw: unMaskedValue,
      };
    }
    if (unMaskedValue.length === 14 && validCnpj(unMaskedValue)) {
      return {
        type: `CNPJ`,
        masked: normalizedValue,
        raw: unMaskedValue,
      };
    }
  } else if (
    normalizedValue?.match(/^\(\d{1,2}\)?\s?\d{0,5}-?\d{0,4}$/) ||
    normalizedValue?.match(/^\+55\d{1,11}$/)
  ) {
    const normalizedOnlyNumber = onlyNumbers(normalizedValue).replace(
      `+55`,
      ``
    );
    return {
      type: `PHONE`,
      masked:
        normalizedOnlyNumber.length > 10
          ? mask(normalizedValue, `(99) 99999-9999`)
          : mask(normalizedValue, `(99) 9999-9999`),
      raw: `+55${normalizedOnlyNumber}`,
    };
  } else if (
    normalizedValue?.match(
      /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/
    )
  ) {
    return {
      type: `EVP`,
      masked: normalizedValue,
      raw: normalizedValue,
    };
  } else if (
    normalizedValue?.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    )
  ) {
    return {
      type: `EMAIL`,
      masked: normalizedValue,
      raw: normalizedValue,
    };
  }
  return { type: `INVALID`, masked: normalizedValue, raw: normalizedValue };
};
