import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  FormControl,
  FormLabel,
  useDisclosure,
  VStack,
  Select,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import { queryClient } from "index";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createIntegration } from "services/api.service";

const CreateIntegration = () => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState("");
  const { handleSubmit, control, reset } = useForm<{
    name: string;
    user: string;
    clientId: string;
    clientSecret: string;
    token: string;
    pixKey: string;
    endpoint: string;
    numeroContrato: string;
  }>();
  const save = async (dataForm: {
    name: string;
    user: string;
    token: string;
    clientId: string;
    clientSecret: string;
    pixKey: string;
    endpoint: string;
    numeroContrato: string;
  }) => {
    setLoading(true);
    createIntegration({
      name: dataForm.name,
      token: dataForm.token,
      type: type,
      user: dataForm.user,
      active: true,
      clientId: dataForm.clientId,
      clientSecret: dataForm.clientSecret,
      pixKey: dataForm.pixKey,
      endpoint: dataForm.endpoint,
      numeroContrato: dataForm.numeroContrato,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Integração adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao criar integração",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <CustomButton
        isDisabled={false}
        colorScheme="brand"
        variant="solid"
        leftIcon={<FaPlus />}
        onClick={() => {
          onOpen();
          reset();
        }}
      >
        Nova Integração
      </CustomButton>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adicionar Integração</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  label="Nome"
                  name="name"
                  type="text"
                  placeholder="Nome para identificar integração"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <FormControl>
                  <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                    Tipo de Integração
                  </FormLabel>
                  <Select
                    placeholder="Selecione o tipo da integração."
                    name="type"
                    onChange={(value) => {
                      setType(value.target.value);
                    }}
                  >
                    <option value="PAG_SEGURO">Pag Seguro</option>
                    <option value="PAG_SEGURO_EDI">
                      Pag Seguro Conciliação
                    </option>
                    <option value="SICOOB">Sicoob</option>
                    <option value="SULCREDI">Sulcredi Pix</option>
                    <option value="SULCREDI_CASHOUT">Sulcredi Contas</option>
                    <option value="MERCADO_PAGO">Mercado Pago</option>
                    <option value="EYE_MOBILE">Eye Mobile</option>
                  </Select>
                </FormControl>
                <InputForm
                  control={control}
                  label="Usuário da Integração"
                  name="user"
                  type="text"
                  placeholder="Usuário/e-mail/conta"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                {["MERCADO_PAGO"].includes(type) && (
                  <>
                    <InputForm
                      control={control}
                      label="Chave Publica"
                      name="clientId"
                      type="text"
                      placeholder="Chave publica"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Endpoint"
                      name="endpoint"
                      type="text"
                      placeholder="https://xxx.xx"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </>
                )}
                {["SULCREDI", "SULCREDI_CASHOUT"].includes(type) && (
                  <>
                    <InputForm
                      control={control}
                      label="Endpoint"
                      name="endpoint"
                      type="text"
                      placeholder="https://xxx.xx"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Client ID"
                      name="clientId"
                      type="text"
                      placeholder="Cliente ID"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Client Secret"
                      name="clientSecret"
                      type="text"
                      placeholder="Client Secret"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </>
                )}
                {["EYE_MOBILE"].includes(type) && (
                  <>
                    <InputForm
                      control={control}
                      label="Client ID"
                      name="clientId"
                      type="text"
                      placeholder="Cliente ID"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Client Secret"
                      name="clientSecret"
                      type="text"
                      placeholder="Client Secret"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </>
                )}
                {["SULCREDI"].includes(type) && (
                  <InputForm
                    control={control}
                    label="Chave Pix"
                    name="pixKey"
                    type="text"
                    placeholder="Chave Pix"
                    rules={{
                      required: "Campo obrigatório",
                    }}
                  />
                )}
                {["SICOOB"].includes(type) && (
                  <>
                    <InputForm
                      control={control}
                      label="Client ID"
                      name="clientId"
                      type="text"
                      placeholder="Cliente ID"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                    <InputForm
                      control={control}
                      label="Número Contrato"
                      name="numeroContrato"
                      type="text"
                      placeholder="Numero Contrato"
                      rules={{
                        required: "Campo obrigatório",
                      }}
                    />
                  </>
                )}
                {["PAG_SEGURO", "PAG_SEGURO_EDI", "MERCADO_PAGO"].includes(
                  type
                ) && (
                  <InputForm
                    control={control}
                    label="Token"
                    name="token"
                    type="text"
                    placeholder="Token para autenticação"
                    rules={{
                      required: "Campo obrigatório",
                    }}
                  />
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateIntegration;
