// chakra imports
import {
  Box,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
//   Custom components
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import useAuth from "contexts/useAuth";
import { FaSignOutAlt } from "react-icons/fa";
import { getVariable } from "whitelabel";

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[] }) {
  const { routes } = props;
  const { signOut } = useAuth();
  let textColor = useColorModeValue("secondaryGray.500", "white");

  // SIDEBAR
  return (
    <Flex direction="column" borderRadius="30px" height="100%">
      <Image src={getVariable("logo")} width="200px" />

      <Stack direction="column" mt="8px" mb="auto">
        <Box
          ps="20px"
          pe={{ lg: "16px", "2xl": "16px" }}
          height={{
            base: "calc(100vh - 200px)",
            lg: "calc(100vh - 60px)",
            "2xl": "calc(100vh - 60px)",
          }}
          overflowY="auto"
        >
          <Links routes={routes} />
          <Box>
            <HStack
              py="5px"
              ps="10px"
              onClick={() => signOut()}
              cursor="pointer"
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box
                  me="18px"
                  alignItems={"center"}
                  display={"flex"}
                  height={"22px"}
                  color={textColor}
                >
                  <FaSignOutAlt />
                </Box>
                <Text me="auto" color={textColor} fontWeight="normal">
                  Sair
                </Text>
              </Flex>
              <Box h="36px" w="4px" bg="transparent" borderRadius="5px" />
            </HStack>
          </Box>
        </Box>
      </Stack>

      <Box
        ps="20px"
        pe={{ lg: "16px", "2xl": "20px" }}
        mt="60px"
        mb="40px"
        borderRadius="30px"
      >
        <SidebarCard />
      </Box>
    </Flex>
  );
}

export default SidebarContent;
