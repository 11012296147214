import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  VStack,
  Select,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import useAuth from "contexts/useAuth";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import {
  createWallet,
  findAllClients,
  findAllCompanys,
} from "services/api.service";

const ModalAddWallet: FC<{
  onClose: () => void;
  isOpen: boolean;
}> = ({ onClose, isOpen }) => {
  const toast = useToast();
  const [companyId, setCompanyId] = React.useState<string | undefined>(
    undefined
  );
  const { userData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [typeId, setTypeId] = React.useState<"QRES" | "DICT">("QRES");
  const { control, handleSubmit, register, setValue } = useForm<{
    customer: SelectOptionType;
    company: SelectOptionType;
    type: "QRES" | "DICT";
    amount: number;
    keyPix: string;
    description: string;
  }>();

  const save = async (dataForm: {
    customer: SelectOptionType;
    company: SelectOptionType;
    amount: number;
    keyPix: string;
    type: "QRES" | "DICT";
    description: string;
  }) => {
    setLoading(true);
    createWallet({
      customerId: dataForm?.customer?.value,
      amount: dataForm.amount,
      type: dataForm.type,
      keyPix: dataForm.keyPix,
      description: dataForm.description,
    })
      .then(() => {
        onClose();
        toast({
          title: "Sucesso",
          description: "Terminal adicionada com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao alterar vínculo",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar Carteira</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={5}>
            <Select
              placeholder="Selecione o tipo do dado."
              {...register("type", { required: true })}
              onChange={(e) => {
                setValue("type", e.target.value as any);
                setTypeId(e.target.value as any);
              }}
            >
              <option value="QRES">QRCode</option>
              <option value="DICT">Chave Pix</option>
            </Select>

            <InputForm
              control={control}
              name="description"
              label="Descrição"
              placeholder="Descrição"
              type="text"
            />

            {typeId === "DICT" ? (
              <InputForm
                control={control}
                name="keyPix"
                label="Chave Pix"
                placeholder="Chave Pix"
                type="text"
              />
            ) : (
              <InputNumber
                control={control}
                name="amount"
                label="Valor da cobrança. Se for um valor fixo, informe o valor. Se for um valor variável, informe 0."
                placeholder="amount"
              />
            )}

            {!userData.isCustomerAdmin && (
              <FormRemoteSelectInput
                control={control}
                name="company"
                registerOptions={{
                  required: "Campo obrigatório",
                }}
                label="Empresa"
                onChange={(value) => {
                  setValue("company", value);
                  setCompanyId(value?.value);
                }}
                loadDataFn={({ value, cb }) =>
                  findAllCompanys({
                    filter: value,
                    limit: 10,
                    page: 0,
                    companyId: companyId,
                  }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: d.name,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  })
                }
              />
            )}
            {(companyId || userData.isCustomerAdmin) && (
              <FormRemoteSelectInput
                control={control}
                label="Cliente"
                isDisabled={!companyId && !userData.isCustomerAdmin}
                filters={{
                  companyId,
                }}
                loadDataFn={({ value, cb }) =>
                  findAllClients({ filter: value, companyId }).then(
                    (retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    }
                  )
                }
                name="customer"
              />
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={() => {
                onClose();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="green"
              isLoading={loading}
              onClick={handleSubmit(save)}
            >
              Adicionar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddWallet;
