import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useToast,
  useDisclosure,
  Progress,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { MdOutlineCached } from "react-icons/md";
import {
  findAllClients,
  relatedMovementToCustomer,
} from "services/api.service";

const ChangeMovementsToTerminal: React.FC<{
  ids: string[];
  refetch: () => void;
}> = ({ ids, refetch }) => {
  const [loading, setLoading] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const toast = useToast();
  const [updates, setUpdates] = React.useState(0);

  const { control, handleSubmit } = useForm<{ customer: SelectOptionType }>({});

  const save = async (data: { customer: SelectOptionType }) => {
    setLoading(true);

    for await (const id of ids) {
      await relatedMovementToCustomer({
        movementId: id,
        customerId: data.customer.value,
      })
        .catch((err) => {
          toast({
            title: "Erro",
            description:
              err?.response?.data?.message || "Erro ao relacionar movimento",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setUpdates((prev) => prev + 1);
        });
    }

    toast({
      title: "Sucesso",
      description: "Movimentos relacionados com sucesso",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    setDone(true);
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      setUpdates(0);
      setDone(false);
    }
  }, [isOpen]);

  const percent = (updates / ids.length) * 100;

  return (
    <>
      <CustomButton
        isDisabled={ids.length === 0}
        colorScheme="green"
        leftIcon={<MdOutlineCached />}
        onClick={onOpen}
      >
        Alterar movimentos
      </CustomButton>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Alterar {ids.length} Movimentos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading || done ? (
              <Progress size="xs" w={"100%"} value={percent} />
            ) : (
              <>
                <FormRemoteSelectInput
                  control={control}
                  label="Cliente"
                  required
                  registerOptions={{ required: "Esse campo é obrigatório" }}
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="customer"
                />
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <HStack spacing={4}>
              <Button
                colorScheme="red"
                isLoading={loading}
                onClick={() => {
                  onClose();
                  refetch();
                }}
              >
                {done ? "Fechar" : "Cancelar"}
              </Button>
              <Button
                colorScheme="green"
                isLoading={loading}
                isDisabled={done}
                onClick={handleSubmit(save)}
              >
                Alterar
              </Button>
            </HStack>
          </ModalFooter>
          ;
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChangeMovementsToTerminal;
