// Chakra imports
import {
  Badge,
  Box,
  ButtonGroup,
  Flex,
  IconButton,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";

import { useMemo, useState } from "react";
import { findAllMovementsToAntecipate } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import TableComponent from "components/TableComponent";
import { FaEye } from "react-icons/fa";
import { MovementsType, MovementsTypeDescription } from "types/Movement";
import useAuth, { UserPermissions } from "contexts/useAuth";
import AntecipateTransactions from "./components/AntecipateTransactions";
import { queryClient } from "index";

export enum MovementsStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
}

export const StatusRender = (status: MovementsStatus) => {
  if (status === MovementsStatus.PENDING)
    return <Badge colorScheme="orange">Pendente</Badge>;
  if (status === MovementsStatus.APPROVED)
    return <Badge colorScheme="green">Aprovado</Badge>;
  if (status === MovementsStatus.REFUNDED)
    return <Badge colorScheme="orange">Devolvido</Badge>;
  if (status === MovementsStatus.REJECTED)
    return <Badge colorScheme="red">Rejeitado</Badge>;
  if (status === MovementsStatus.CANCELED)
    return <Badge colorScheme="red">Cancelado</Badge>;
};

export default function Antecipations() {
  const history = useHistory();
  const [rows, setSelectedRows] = useState<string[]>([]);

  const { hasPermission } = useAuth();

  const writePermission = hasPermission(UserPermissions.MOVEMENTS_WRITE);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Cliente",
          accessor: "name",
        },
        {
          Header: "Tipo",
          filter: true,
          values: [
            {
              value: MovementsType.PIX,
              label: MovementsTypeDescription[MovementsType.PIX],
            },
            {
              value: MovementsType.CREDIT_CARD,
              label: MovementsTypeDescription[MovementsType.CREDIT_CARD],
            },
            {
              value: MovementsType.DEBIT_CARD,
              label: MovementsTypeDescription[MovementsType.DEBIT_CARD],
            },
            {
              value: MovementsType.CASH,
              label: MovementsTypeDescription[MovementsType.CASH],
            },
            {
              value: MovementsType.TICKET,
              label: MovementsTypeDescription[MovementsType.TICKET],
            },
          ],
          accessor: "type",
          Cell: ({ value }) => {
            return MovementsTypeDescription[value];
          },
        },
        {
          Header: "Status",
          accessor: "status",
          filter: true,
          values: [
            {
              value: MovementsStatus.PENDING,
              label: "Pendente",
            },
            {
              value: MovementsStatus.APPROVED,
              label: "Aprovado",
            },
            {
              value: MovementsStatus.REJECTED,
              label: "Rejeitado",
            },
            {
              value: MovementsStatus.CANCELED,
              label: "Cancelado",
            },
          ],
          Cell: ({ value }: { value: MovementsStatus }) => {
            return StatusRender(value);
          },
        },
        {
          Header: "Valor",
          accessor: "amount",
          align: "right",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          Header: "Taxa Antecipação",
          accessor: "antecipate_fee",
          align: "right",
          Cell: ({ value }) => {
            return +value + "%";
          },
        },
        {
          Header: "Taxas",
          align: "right",
          accessor: "taxes",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },
        {
          Header: "Valor Líquido",
          align: "right",
          accessor: "amount_liquid",
          Cell: ({ value }) => {
            return (+value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        },

        {
          accessor: "Maquinetas.number",
          Header: "Terminal",
          Cell: ({ value, row }: any) => {
            return value ? (
              <Tooltip label={row.original.Maquinetas?.name || "Venda"}>
                {value}
              </Tooltip>
            ) : (
              "Venda API"
            );
          },
        },

        {
          accessor: "order_at",
          Header: "Data",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <Flex align="center" justify="flex-start" w="100%">
      <IconButton
        aria-label="Apertar"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => history.push(`/admin/movement/${row.id}`)}
      />
    </Flex>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          filterable={[
            "customerId",
            "maquinetaId",
            "createdAt",
            "onlyCharges",
            "promoterId",
          ]}
          queryFn={findAllMovementsToAntecipate}
          aggregateSelection={[
            { key: "amount", label: "Total (Sem Antecipação)" },
            {
              key: "amount_liquid",
              label: "Total Líquido (Sem Antecipação)",
            },
          ]}
          setSelectedRows={setSelectedRows}
          enableSelect
          right={
            <ButtonGroup>
              {writePermission && (
                <AntecipateTransactions
                  ids={rows}
                  refetch={() =>
                    queryClient.refetchQueries({
                      queryKey: ["findAllMovementsToAntecipate"],
                      type: "active",
                    })
                  }
                />
              )}
            </ButtonGroup>
          }
          queryKey="findAllMovementsToAntecipate"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
