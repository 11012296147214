import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomButton from "components/CustomButton";
import InputForm from "components/InputForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FiCheck, FiX } from "react-icons/fi";
import { registerCode } from "services/api.service";

interface EnableSafeCodeForm {
  newCode: string;
  code?: string;
}

const EnableSafeCode: React.FC<{ isEnabled: boolean }> = ({ isEnabled }) => {
  const toast = useToast();
  const { handleSubmit, control, reset } = useForm<EnableSafeCodeForm>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function isValidCode(code) {
    // Verifica se é um código de 6 dígitos
    if (!/^\d{6}$/.test(code)) {
      return false;
    }

    // Verifica se há 3 números repetidos consecutivos
    if (/(.)\1\1/.test(code)) {
      return false;
    }

    // Verifica se há qualquer sequência de 3 dígitos
    for (let i = 0; i < code.length - 2; i++) {
      const num1 = parseInt(code[i], 10);
      const num2 = parseInt(code[i + 1], 10);
      const num3 = parseInt(code[i + 2], 10);

      if (
        (num2 === num1 + 1 && num3 === num2 + 1) || // Sequência ascendente
        (num2 === num1 - 1 && num3 === num2 - 1)
      ) {
        // Sequência descendente
        return false;
      }
    }

    return true;
  }

  const onSubmit = async (data: EnableSafeCodeForm) => {
    if (!isOpen) return;

    const valid = isValidCode(data.newCode);

    if (!valid) {
      toast({
        title: `Atenção`,
        description: `O código
        deve conter 6 dígitos e não pode ter 3 números sequenciais ou repetidos`,
        position: `top`,
        status: `warning`,
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    registerCode({
      newCode: data.newCode,
      code: data.code,
    })
      .then(() => {
        toast({
          title: `2FA ativado com sucesso`,
          position: `top`,
          status: `success`,
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((err) => {
        toast({
          title: `Atenção`,
          description: err.response?.data?.message || `Erro ao ativar 2FA`,
          position: `top`,
          status: `warning`,
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <CustomButton
        leftIcon={<FiCheck />}
        colorScheme={`brand`}
        onClick={onOpen}
        variant="solid"
      >
        {isEnabled ? `Alterar Código` : `Ativar Código`}
      </CustomButton>
      <Modal size={`md`} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Box p={5}>
            <Text fontSize={`sm`} mt={5}>
              VERIFICAÇÃO
            </Text>
            <Divider mb={5} />
            <Text fontSize={`sm`} mb={5}>
              Para salvar a sua senha, digite a seguir...
            </Text>
            <Text fontWeight="bold" fontSize={`lg`} mb={5}>
              Essa senha será solicitada ao realizar operações críticas em sua
              conta.
            </Text>
            <Divider mb={5} />
            {isEnabled && (
              <InputForm
                control={control}
                name="code"
                label="Código atual"
                type="password"
                isDisabled={isLoading}
                placeholder="Código atual"
                labelProps={{
                  fontSize: 10,
                  textColor: `gray.500`,
                }}
                rules={{
                  required: `Informe o código de autorização`,
                  minLength: {
                    value: 6,
                    message: `O código deve ter no mínimo 6 caracteres`,
                  },

                  maxLength: {
                    value: 6,
                    message: `O código deve ter no máximo 6 caracteres`,
                  },
                }}
              />
            )}

            <Divider mb={5} mt={2} />

            <InputForm
              control={control}
              name="newCode"
              label="Código de autorização"
              type="password"
              isDisabled={isLoading}
              placeholder="Código de 6 dígitos"
              labelProps={{
                fontSize: 10,
                textColor: `gray.500`,
              }}
              rules={{
                required: `Informe o código de autorização`,
                minLength: {
                  value: 6,
                  message: `O código deve ter no mínimo 6 caracteres`,
                },

                maxLength: {
                  value: 6,
                  message: `O código deve ter no máximo 6 caracteres`,
                },
              }}
            />

            <Divider my={5} />
            <HStack justifyContent={`END`}>
              <Button
                leftIcon={<FiX />}
                isLoading={isLoading}
                size={`sm`}
                color={`gray.500`}
                onClick={onClose}
                rounded={`sm`}
              >
                Fechar
              </Button>

              <Button
                leftIcon={<FiCheck />}
                rounded={`sm`}
                size={`sm`}
                isLoading={isLoading}
                colorScheme={`brand`}
                onClick={handleSubmit(onSubmit)}
              >
                {isEnabled ? `Alterar Código` : `Ativar Código`}
              </Button>
            </HStack>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EnableSafeCode;
