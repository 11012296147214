import { Button } from "@chakra-ui/react";
import React, { JSXElementConstructor, ReactElement } from "react";

const CustomButton: React.FC<{
  onClick: () => void;
  children: JSX.Element | string;
  leftIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  colorScheme?: string;
  isDisabled?: boolean;
  variant?: string;
  isLoading?: boolean;
}> = ({ variant = "outline", children, colorScheme = "brand", ...props }) => {
  return (
    <Button
      {...props}
      size="sm"
      rounded={"md"}
      variant={variant}
      colorScheme={colorScheme}
      width={{ base: "100%", md: "auto" }}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
