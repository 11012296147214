import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  IconButton,
  Tooltip,
  ModalFooter,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { FaPeopleArrows } from "react-icons/fa";
import { listEyeClients } from "./api";
import AddClientEye from "./AddClientEye";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const ShowEyeClients: React.FC<{
  eyeIntegrationId: number;
  integrationId: string;
}> = ({ eyeIntegrationId, integrationId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, refetch } = useQuery(
    ["listEyeClients", eyeIntegrationId],
    () => listEyeClients(eyeIntegrationId),
    {
      enabled: isOpen,
    }
  );

  return (
    <>
      {
        <Tooltip label="Exibir clientes">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            size="sm"
            colorScheme={"blue"}
            variant="outline"
            icon={<FaPeopleArrows />}
            onClick={onOpen}
          />
        </Tooltip>
      }
      {isOpen && (
        <Modal
          size={{
            base: "full",
            md: "2xl",
          }}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Clientes</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} spacing={3}>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Id</Th>
                        <Th>Nome</Th>
                        <Th>Status</Th>
                        <Th>Formas</Th>
                        <Th>Opções</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data?.map((point) => (
                        <Tr key={point.id}>
                          <Td>{point.id}</Td>
                          <Td>{point.customer.name}</Td>
                          <Td>{"Ativo"}</Td>
                          <Td>{point.paymentForms.join(",")}</Td>
                          <Td>
                            <AddClientEye
                              integrationId={integrationId}
                              eyeIntegrationId={eyeIntegrationId}
                              refetch={refetch}
                              editData={{
                                customer: {
                                  label: point.customer.name,
                                  value: point.customer.id,
                                },
                                event: {
                                  label: "Evento",
                                  value: String(point.eventId),
                                },
                                paymentTypes: point.paymentForms,
                                id: point.id,
                              }}
                            ></AddClientEye>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <AddClientEye
                integrationId={integrationId}
                eyeIntegrationId={eyeIntegrationId}
                refetch={refetch}
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ShowEyeClients;
