import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  IconButton,
  Tooltip,
  useToast,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import CustomButton from "components/CustomButton";
import React from "react";
import { FaDraftingCompass } from "react-icons/fa";
import { createLinkEye, findAllEyePoints, removeLinkEye } from "./api";
import ShowEyeClients from "./ShowEyeClients";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const EyeMobileIntegration: React.FC<{ data: IntegrationResponse }> = ({
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [showInactives, setShowInactives] = React.useState(false);

  const { data: points, refetch } = useQuery(
    [data.id, "points"],
    () => findAllEyePoints({ integrationId: data.id, limit: 10, page: 0 }),
    {
      enabled: isOpen,
    }
  );

  const handleProcess = (pointId: number) => {
    createLinkEye({ integrationId: data.id, pointId: +pointId })
      .then(() => {
        refetch();
        toast({
          title: "Link criado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao criar link",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const removeLink = (id: number) => {
    removeLinkEye(id)
      .then(() => {
        toast({
          title: "Link removido com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao remover link",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      {data.type === "EYE_MOBILE" && (
        <Tooltip label="Configurar Eye Mobile">
          <IconButton
            aria-label="Apertar"
            rounded="md"
            isDisabled={!data.identifierUrl}
            size="sm"
            variant="outline"
            icon={<FaDraftingCompass />}
            onClick={onOpen}
          />
        </Tooltip>
      )}
      {isOpen && (
        <Modal
          size={{
            base: "full",
            md: "2xl",
          }}
          isOpen={isOpen}
          onClose={onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Eye Mobile</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <TableContainer>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Id</Th>
                      <Th>Nome</Th>
                      <Th>Status</Th>
                      <Th>Configurado</Th>
                      <Th isNumeric>Acões</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {points
                      ?.filter((point) => {
                        if (showInactives) return true;
                        return point.status === 1;
                      })
                      .map((point) => (
                        <Tr
                          key={point.id}
                          bg={point.status === 0 ? "red.100" : "white"}
                        >
                          <Td>{point.id}</Td>
                          <Td>{point.name}</Td>
                          <Td>{point.status ? "Ativo" : "Inativo"}</Td>
                          <Td>
                            {point.links ? (
                              <HStack>
                                <Text mr={2}>Sim</Text>
                                {
                                  <ShowEyeClients
                                    eyeIntegrationId={point.links.id}
                                    integrationId={data.id}
                                  />
                                }
                              </HStack>
                            ) : (
                              "Não"
                            )}
                          </Td>
                          <Td isNumeric>
                            {!point.links ? (
                              <CustomButton
                                isDisabled={point.status === 0}
                                onClick={() => handleProcess(+point.id)}
                                colorScheme="brand"
                              >
                                Configurar
                              </CustomButton>
                            ) : (
                              <CustomButton
                                isDisabled={point.status === 0}
                                onClick={() => removeLink(+point.links.id)}
                                colorScheme="red"
                              >
                                Remover
                              </CustomButton>
                            )}
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              <HStack mb={5} spacing={3}>
                <CustomButton
                  colorScheme="brand"
                  onClick={() => {
                    setShowInactives(!showInactives);
                  }}
                >
                  Mostrar inativos
                </CustomButton>
                <CustomButton colorScheme="brand" onClick={() => {}}>
                  Configurar
                </CustomButton>
              </HStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EyeMobileIntegration;
