import {
  Box,
  Button,
  Divider,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import {
  createContext,
  createRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { FiX } from "react-icons/fi";
import { useKeyPressEvent } from "react-use";
import { validateUserCode } from "services/api.service";

const pinInputProps = {
  _focus: {
    borderColor: `brand.500`,
    boxShadow: `none`,
  },
};

interface TwoFaCheckContextParams {
  requestTwoFa(): Promise<{
    id: string;
  } | null>;
}

export const TwoFaCheckContext = createContext<TwoFaCheckContextParams>(
  {} as any
);

export const useTwoFaHelper = () => useContext(TwoFaCheckContext);

export const TwoFaHelperProvider = ({ children }) => {
  const [promiseFn, setPromiseFn] = useState<{
    resolve: (value: { id: string } | null) => void;
    reject: (reason?: any) => void;
  }>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [twoFa, setTwoFa] = useState(``);
  const [codeError, setCodeError] = useState<string | undefined>();
  const { mutateAsync, isLoading } = useMutation(validateUserCode, {
    retry: false,
  });
  const toast = useToast();

  const requestTwoFa = (): Promise<{
    id: string;
  }> => {
    setTwoFa(``);
    return new Promise<{
      id: string;
    }>(function handleConfirmation(resolve, reject) {
      setPromiseFn({ resolve, reject });
      onOpen();
    });
  };

  const handleOK = (data: string) => {
    mutateAsync(data)
      .then((res) => {
        if (res) {
          promiseFn?.resolve(res);
          onClose();
        } else {
          setTwoFa(``);
          setCodeError(`Código inválido`);
        }
      })
      .catch((err) => {
        isAxiosError &&
          toast({
            title: `Erro`,
            isClosable: true,
            duration: 5000,
            description:
              err.response?.data?.message || `Erro ao validar código`,
            status: `error`,
          });
        setTwoFa(``);
        setCodeError(`Código inválido`);
      });
  };

  const handleCancel = () => {
    promiseFn?.resolve(null);
    onClose();
  };

  useKeyPressEvent(`Enter`, handleOK as any);
  const firstPinInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (codeError && firstPinInputRef.current) {
      firstPinInputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeError]);

  return (
    <TwoFaCheckContext.Provider value={{ requestTwoFa }}>
      {children}
      <Modal size={`md`} isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <Box p={5}>
            <Text textAlign={`center`}>AUTENTICAÇÃO DE DOIS FATORES (2FA)</Text>
            <Divider my={5} />
            <Text fontSize={`sm`} mb={5}>
              Informe o código de autenticação gerado pelo aplicativo
            </Text>
            <HStack w={`full`} justifyContent={`center`}>
              <PinInput
                placeholder="•"
                size={`lg`}
                value={twoFa}
                onChange={(v) => {
                  setCodeError(undefined);
                  setTwoFa(v);
                }}
                isInvalid={!!codeError}
                onComplete={handleOK}
                isDisabled={isLoading}
              >
                <PinInputField
                  {...pinInputProps}
                  ref={firstPinInputRef}
                  autoFocus
                />
                <PinInputField {...pinInputProps} />
                <PinInputField {...pinInputProps} />
                <PinInputField {...pinInputProps} />
                <PinInputField {...pinInputProps} />
                <PinInputField {...pinInputProps} />
              </PinInput>
            </HStack>

            <Divider my={5} />

            <HStack justifyContent={`end`}>
              <Button
                leftIcon={<FiX />}
                size={`sm`}
                onClick={handleCancel}
                rounded={`sm`}
              >
                Cancelar
              </Button>
            </HStack>
          </Box>
        </ModalContent>
      </Modal>
    </TwoFaCheckContext.Provider>
  );
};
