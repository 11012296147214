import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  IconButton,
  Tooltip,
  Text,
  HStack,
  Button,
  useToast,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  ButtonGroup,
} from "@chakra-ui/react";
import React from "react";
import { FaCopy, FaEdit } from "react-icons/fa";
import {
  removeIntegration,
  fetchBalanceIntegration,
  removeIntegrationFromCompany,
  activeFeatureIntegration,
  removeFeatureIntegration,
  activateWebhook,
} from "services/api.service";
import IntegrationToCompany from "./IntegrationToCompany";
import { queryClient } from "index";
import { useMutation } from "@tanstack/react-query";
import { maskCurrency } from "utils/number";
import { isAxiosError } from "axios";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

interface Feature {
  id: string;
  type: string;
  active: boolean;
  priority: number;
}

export interface IntegrationReceiverResponse {
  id: string;
  integrationId: string;
  payload: Payload;
  createdAt: string;
  updatedAt: string;
  Integration: Integration;
}

interface Integration {
  name: string;
}

interface Payload {
  pix?: Pix[];
  id?: string;
  valor?: string;
  estado?: string;
}

interface Pix {
  txid: string;
  valor: string;
  horario: string;
  devolucoes: any[];
  endToEndId: string;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
  IntegrationFeatures: Feature[];
  IntegrationCompanys: {
    id: string;
    companyId: string;
    deletedAt: Date;
    Company: Company;
  }[];
}

enum CashoutAPICheck {
  SULCREDI_CASHOUT = "SULCREDI_CASHOUT",
  SICOOB = "SICOOB",
}

enum FeatureDescription {
  BILLET = "Recebimento de Boletos",
  PIX = "Recebimento de Pix",
  CASHOUT = "Saída de Pix",
  BILLET_PAYMENT = "Pagamento de Boletos",
  CREDIT_CARD = "Recebimento de Cartão de Crédito Online",
}

const FeaturesByIntegration = {
  SULCREDI_CASHOUT: ["BILLET_PAYMENT", "CASHOUT"],
  SULCREDI: ["PIX"],
  SICOOB: ["BILLET", "CASHOUT", "PIX", "BILLET_PAYMENT"],
  PAG_SEGURO: ["PIX", "CREDIT_CARD"],
  CIELO: ["CREDIT_CARD"],
  MERCADO_PAGO: ["CREDIT_CARD"],
};

const SeeIntegration: React.FC<{
  data: IntegrationResponse;
  disabled: boolean;
}> = ({ data, disabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_API_URL}/receiver/${data.identifierUrl}`
    );
  };

  const {
    mutateAsync: fetchBalanceAsync,
    data: balanceData,
    isLoading: isLoadingBalance,
  } = useMutation(["consulta-balance", data.id], () =>
    fetchBalanceIntegration(data?.id)
  );

  const { mutateAsync: activeFeature } = useMutation(
    ["active-feature"],
    ({ type }: { type: string }) =>
      activeFeatureIntegration({ id: data?.id, type }),
    {
      onSuccess(data, variables, context) {
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
      },
      onError: (err) => {
        if (isAxiosError(err)) {
          toast({
            title: "Erro ao ativar feature",
            description: err?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      },
    }
  );

  const { mutateAsync: activeWebook } = useMutation(
    ["active-webhook"],
    ({ type }: { type: string }) => activateWebhook({ id: data?.id, type }),
    {
      onSuccess(data, variables, context) {
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
        toast({
          title: "Webhook ativado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      },
      onError: (err) => {
        if (isAxiosError(err)) {
          toast({
            title: "Erro ao ativar webhook",
            description: err?.message,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      },
    }
  );

  const { mutateAsync: inactiveFeature } = useMutation(
    ["inactive-feature"],
    ({ type }: { type: string }) =>
      removeFeatureIntegration({ id: data?.id, type }),
    {
      onSuccess(data, variables, context) {
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
      },
    }
  );

  const handleRemoveIntegration = () => {
    removeIntegration(data.id)
      .then(() => {
        toast({
          title: "Integração removida com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao remover integração",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const handleRemoveIntegrationFromCompany = (companyId: string) => {
    removeIntegrationFromCompany({
      companyId,
      integrationId: data.id,
    })
      .then(() => {
        toast({
          title: "Integração removida com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        queryClient.refetchQueries({
          queryKey: ["findAllIntegrations"],
          type: "active",
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao remover integração",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const fetchBalance = () => {
    fetchBalanceAsync()
      .then(() => {
        toast({
          title: `Consulta de saldo realizada com sucesso.`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro ao consultar saldo",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Tooltip label="Dados de integração">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          isDisabled={!data.identifierUrl}
          size="sm"
          colorScheme={"brand"}
          variant="outline"
          icon={<FaEdit />}
          onClick={onOpen}
        />
      </Tooltip>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Ver Integração</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} spacing={3}>
                <VStack alignItems="start" width="100%">
                  <Text size="xs" color="gray.600">
                    Nome da Integração
                  </Text>
                  <Text m="0" as="span">
                    {data.name}
                  </Text>
                </VStack>
                <VStack alignItems="start" width="100%">
                  <Text size="xs" color="gray.600">
                    Usuário
                  </Text>
                  <Text m="0" as="span">
                    {data.user}
                  </Text>
                </VStack>
                <VStack alignItems="start" width="100%">
                  <Text size="xs" color="gray.600">
                    Url para configurar
                  </Text>
                  <Tooltip label="Copiar">
                    <HStack>
                      <Text
                        cursor="pointer"
                        m="0"
                        as="span"
                        onClick={handleCopy}
                      >
                        {`${process.env.REACT_APP_API_URL}/receiver/${data.identifierUrl}`}
                      </Text>
                      <FaCopy />
                    </HStack>
                  </Tooltip>
                </VStack>
                <Text as="small">
                  Informe a URL na conta desse usuário para começar a receber as
                  vendas
                </Text>
                {!!CashoutAPICheck[data?.type] && (
                  <>
                    <VStack w="100%">
                      <Text
                        size="md"
                        color="gray.600"
                        fontWeight="bold"
                        w="100%"
                      >
                        Carregar saldo
                      </Text>
                      <Button
                        size="sm"
                        rounded="sm"
                        variant="outline"
                        colorScheme="blue"
                        isLoading={isLoadingBalance}
                        isDisabled={disabled}
                        onClick={fetchBalance}
                      >
                        Consultar Saldo
                      </Button>
                      {isLoadingBalance && (
                        <Text size="xs" color="gray.600">
                          Consultando saldo...
                        </Text>
                      )}
                      {balanceData && (
                        <HStack w="100%">
                          <Text w="100%" size="xs" color="gray.600">
                            Saldo:{" "}
                            <Text fontWeight="bold">
                              {maskCurrency(balanceData?.balance)}
                            </Text>
                          </Text>
                          <Text w="100%" size="xs" color="gray.600">
                            Saldo bloqueado:{" "}
                            <Text fontWeight="bold">
                              {maskCurrency(balanceData?.balanceBlocked)}
                            </Text>
                          </Text>
                        </HStack>
                      )}
                    </VStack>
                  </>
                )}

                <VStack w="100%">
                  <Text size="md" color="gray.600" fontWeight="bold" w="100%">
                    Funcionalidades
                  </Text>
                  {
                    <VStack w="100%">
                      <Text w="100%" size="xs" color="gray.600">
                        {data.IntegrationFeatures.map((feature) => (
                          <VStack alignItems={"start"}>
                            <Text key={feature.id}>
                              {feature.active ? "✅" : "❌"}{" "}
                              {FeatureDescription[feature.type]} -
                              <b>{feature.active ? "ATIVO" : "INATIVO"}</b>
                            </Text>

                            <ButtonGroup>
                              <Button
                                size="xs"
                                rounded="sm"
                                variant="outline"
                                colorScheme="blue"
                                onClick={() => {
                                  feature.active
                                    ? inactiveFeature({ type: feature.type })
                                    : activeFeature({ type: feature.type });
                                }}
                              >
                                {feature.active ? "Desativar" : "Ativar"}
                              </Button>
                              <Button
                                size="xs"
                                rounded="sm"
                                variant="outline"
                                colorScheme="blue"
                                onClick={() => {
                                  activeWebook({ type: feature.type });
                                }}
                              >
                                Configurar Webhook
                              </Button>
                            </ButtonGroup>
                          </VStack>
                        ))}
                        {FeaturesByIntegration[data.type]
                          ?.filter(
                            (feature) =>
                              !data.IntegrationFeatures.map(
                                (item) => item.type
                              ).includes(feature)
                          )
                          .map((feature) => (
                            <Text key={feature}>
                              ❌ {FeatureDescription[feature]}{" "}
                              <Button
                                size="xs"
                                rounded="sm"
                                variant="outline"
                                colorScheme="blue"
                                onClick={() => {
                                  activeFeature({ type: feature });
                                }}
                              >
                                Ativar
                              </Button>
                            </Text>
                          ))}
                      </Text>
                    </VStack>
                  }
                </VStack>

                <Text size="md" color="gray.600" fontWeight="bold" w="100%">
                  Empresas integradas
                </Text>
                <TableContainer w="100%" p={0}>
                  <Table variant="" p={0}>
                    <Thead>
                      <Tr>
                        <Th>Empresa</Th>
                        <Th>Ação</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {data.IntegrationCompanys.filter(
                        (item) => !item.deletedAt
                      ).map((company) => (
                        <Tr key={company.id}>
                          <Td>{company.Company.name}</Td>
                          <Td>
                            <Button
                              size="sm"
                              rounded="sm"
                              variant="outline"
                              isDisabled={disabled}
                              colorScheme="orange"
                              onClick={() =>
                                handleRemoveIntegrationFromCompany(
                                  company.companyId
                                )
                              }
                            >
                              Remover Integração
                            </Button>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <HStack>
                  {!disabled && (
                    <IntegrationToCompany integrationId={data.id} />
                  )}
                  <Button
                    onClick={handleRemoveIntegration}
                    size="sm"
                    rounded="sm"
                    variant="outline"
                    colorScheme="red"
                    isDisabled={disabled}
                  >
                    Remover Integração
                  </Button>
                </HStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default SeeIntegration;
