import api from "services/api.service";
import { ListPointsEye } from "./types/Api";
import { ClientEye } from "./types/ResponseEyeClient";

export const findAllEyeEvents = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get("eye-mobile/events", { params: filter })
    .then((res) => res.data);
};

export const findAllPayTypes = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get("eye-mobile/pay_types", { params: filter })
    .then((res) => res.data);
};
export const findAllEyePoints = async (filter: {
  integrationId: string;
  page: number;
  limit: number;
}) => {
  return api
    .get<ListPointsEye[]>("eye-mobile/points", { params: filter })
    .then((res) => res.data);
};

export const createLinkEye = async (data: {
  integrationId: string;
  pointId: number;
}) => {
  return api.post<{}>("eye-mobile/link", data).then((res) => res.data);
};

export const removeLinkEye = async (id: number) => {
  return api.delete<{}>(`eye-mobile/link/${id}`).then((res) => res.data);
};

export const listEyeClients = async (id: number) => {
  return api
    .get<ClientEye[]>(`eye-mobile/${id}/clients`)
    .then((res) => res.data);
};

export const addClientEye = async (data: {
  eyeIntegrationId: number;
  customerId: string;
  eventId: string;
  payTypes: string[];
  id?: number;
}) => {
  return api.post<{}>("eye-mobile/client", data).then((res) => res.data);
};
