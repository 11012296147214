// Chakra imports
import { Badge, Box, ButtonGroup, SimpleGrid } from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllIntegrationsLastData } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import TableComponent from "components/TableComponent";
import { IntegrationReceiverResponse } from "./components/SeeIntegration";
import { useParams } from "react-router-dom";
import ShowJsonBody from "./components/ShowJsonBody";

export default function LastNotifications() {
  const { id } = useParams<{ id: string }>();

  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome da Integração",
          accessor: "Integration.name",
        },
        {
          Header: "Status",
          accessor: "active",
          Cell: ({ value }) => {
            return <Badge colorScheme="green">Sucesso</Badge>;
          },
        },
        {
          accessor: "createdAt",
          Header: "Data de Cadastro",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: IntegrationReceiverResponse) => (
    <ButtonGroup>
      <ShowJsonBody json={row.payload}></ShowJsonBody>
    </ButtonGroup>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          queryFn={(filter) => findAllIntegrationsLastData(id, filter)}
          queryKey={`findAllIntegrationsLastData-${id}`}
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
