import axios, { isAxiosError } from "axios";
import { AUTHSTORAGE_KEY, UserPermissions } from "contexts/useAuth";
import { Charge } from "types/Charge";
import { ConsultCep } from "types/ConsultCep";
import { ConsultCnpj } from "types/ConsultCnpj";
import {
  BalancesPending,
  CustomerResponse,
  FreezeMethods,
} from "types/Customers";
import { DashboardResponse } from "types/dashboard";
import { Movement } from "types/Movement";
import { CustomerConfigurations } from "views/admin/customers/components/ConfigurationCustomers";
import { CreateHolderForm } from "views/admin/insurances/components/CreateHolder";
import { Fee, Plan } from "views/admin/taxes/components/Plan.types";
import { Withdrawal } from "views/admin/withdrawals";
import { ShowQrCode } from "./types/QRcode/qrcode.type";
import { OriginTaxesDescription, TaxesType } from "types/Fees";
import { WalletStatus } from "views/admin/wallets";
import { CustomerFeatures } from "views/admin/customers/components/FeaturesCustomers";
import { CardList } from "./types/cards.type";
import { RecurrenceResponse } from "views/admin/recurrences/types/Recurrence.entity";
import { RecurrencyClientPayments } from "views/admin/recurrences/types/RecurrencyPayments.entity";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("Authorization"),
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (isAxiosError(error)) {
      if (
        error.response?.status === 401 &&
        !error.config.baseURL.includes("/login")
      ) {
        localStorage.removeItem(AUTHSTORAGE_KEY);
        window.location.href = "/#/auth";
      } else if (error.response?.status === 403) {
        window.location.href = "/#/admin/no-permission";
      }
    }
    return Promise.reject(error);
  }
);

interface Orders {
  id: number;
  customerName: string;
  providerName: string;
  status: string;
  serviceType: string;
  amount: number;
  on: Date;
}

export interface LoginDataResponse {
  id: string;
  name: string;
  email: string;
  companyId: string;
  twoFa: boolean;
  permissions: UserPermissions[];
  token: string;
}

export const loginService = async (
  email: string,
  password: string,
  captchaKey: string,
  twoFa: string
) => {
  return api
    .post<LoginDataResponse>("users/login", {
      email,
      password,
      twoFa,
      captchaKey,
    })
    .then((res) => res.data);
};

export const refreshUserData = async () => {
  return api.post<LoginDataResponse>("users/refresh").then((res) => res.data);
};
export const findAllUsers = () => {
  return api.get("users").then((res) => res.data);
};

export const findAllUsersGroups = async (filter: string) => {
  return api
    .get("users/groups", { params: { filter } })
    .then((res) => res.data);
};

export const savePermissionsGroup = async (
  id: number,
  permissions: string[]
) => {
  return api
    .post(`users/groups/${id}/permissions`, { permissions })
    .then((res) => res.data);
};

export const deleteGroup = async (id: number) => {
  return api.delete(`users/groups/${id}`).then((res) => res.data);
};

export const createUserGroups = async ({
  name,
  type,
}: {
  name: string;
  type: "USER";
}) => {
  return api.post("users/groups", { name, type }).then((res) => res.data);
};

export const request2FaData = async () => {
  return api
    .get<{ otpauth_url: string; base32: string }>("users/twofa")
    .then((res) => res.data);
};

export const active2FaData = async (token) => {
  return api.post("users/twofa", { token }).then((res) => res.data);
};

export const deleteUserService = (id: string) => {
  return api.delete(`users/${id}`).then((res) => res.data);
};

export const createUser = (data: {
  email: string;
  password: string;
  name: string;
  companyId: string;
  groupId: string;
}) => {
  return api.post(`users`, data).then((res) => res.data);
};

export const updateUser = (
  id: string,
  data: {
    email: string;
    password: string;
    name: string;
    companyId: string;
    groupId: string;
  }
) => {
  return api.put(`users/${id}`, data).then((res) => res.data);
};

interface TaxesRequest {
  origin: OriginTaxesDescription;
  type: TaxesType;
  id?: string;
  releaseInDays: number;
  amount: number;
}
export interface RegisterCustomerForm {
  name: string;
  email: string;
  id?: string;
  password?: string;
  companyId: string;
  document: string;
  planId: number;
  phone?: string;
  taxes?: TaxesRequest[];
}

export const createCustomer = (data: RegisterCustomerForm) => {
  return api.post(`customers/register`, data).then((res) => res.data);
};

export const updateCustomer = (id: string, data: RegisterCustomerForm) => {
  return api.put(`customers/${id}`, data).then((res) => res.data);
};

export const createCustomerTaxes = (customerId: string, fee: TaxesRequest) => {
  return api.post(`customers/${customerId}/taxes`, fee).then((res) => res.data);
};

export const deleteCustomerTaxes = (customerId: string, taxId: string) => {
  return api
    .delete(`customers/${customerId}/taxes/${taxId}`)
    .then((res) => res.data);
};

export const getMovement = (id: string) => {
  return api.get<Movement>(`movements/${id}`).then((res) => res.data);
};

export const cancelMovement = (id: string) => {
  return api.delete<Movement>(`movements/${id}`).then((res) => res.data);
};

export const getCharge = (id: string) => {
  return api.get<Charge>(`charges/${id}`).then((res) => res.data);
};

export const finalizeCharge = async (id: string, integrationId: string) => {
  return api
    .post<Charge>(`charges/${id}/complete`, { integrationId })
    .then((res) => res.data);
};

export const cancelCharge = async (id: string) => {
  return api.post<Charge>(`charges/${id}/cancel`, {}).then((res) => res.data);
};

export const deleteTax = (id: string) => {
  return api.delete(`taxes/${id}`).then((res) => res.data);
};
export const ativeUserService = (id: string, ative: boolean) => {
  return api.put(`users/${id}/ative`, { ative }).then((res) => res.data);
};

export interface Customer {
  id: string;
  name: string;
  document_number: string;
  email: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface CustomerListResponse {
  pages: number;
  registers: Customer[];
}

export const saveManualMovement = async (data: any) => {
  return api.post("movements/manual", data).then((res) => res.data);
};

export const findAllClients = ({
  limit = 20,
  filter = "",
  page = 0,
  companyId,
}: {
  limit?: number;
  page?: number;
  filter?: string;
  companyId?: string;
}) => {
  return api
    .get<CustomerListResponse>("customers", {
      params: {
        limit,
        page,
        companyId,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findAllClientsLocks = ({
  limit = 20,
  filter = "",
  page = 0,
  companyId,
  customerId,
}: {
  limit?: number;
  page?: number;
  filter?: string;
  companyId?: string;
  customerId?: string;
}) => {
  return api
    .get<any>("balancesLocks", {
      params: {
        limit,
        page,
        companyId,
        customerId,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findAllParticipants = ({
  limit = 20,
  filter = "",
  page = 0,
}: {
  limit?: number;
  page?: number;
  filter?: string;
}) => {
  return api
    .get<CustomerListResponse>("withdrawals/participants", {
      params: {
        limit,
        page,
        filter,
      },
    })
    .then((res) => res.data);
};

export const createWithdrawal = async (validateCode: string, data: any) => {
  return api
    .post<any>("withdrawals/customer", data, {
      headers: {
        "x-security-code": validateCode,
      },
    })
    .then((res) => res.data);
};

export const createCharge = (data: any) => {
  return api.post<any>("charges/main", data).then((res) => res.data);
};

export const createTransfer = (token: string, data: any) => {
  return api
    .post<any>("withdrawals/transfer", data, {
      headers: {
        "x-security-code": token,
      },
    })
    .then((res) => res.data);
};

export const transferAmountAnotherCustomer = async (
  customerId: string,
  token: string,
  data: any
) => {
  return api
    .post<any>(`customers/${customerId}/transfer`, data, {
      headers: {
        "x-security-code": token,
      },
    })
    .then((res) => res.data);
};

export const antecipateTransactionCustomer = async ({
  movementId,
}: {
  movementId: string;
}) => {
  return api.post(`movements/${movementId}/antecipate`).then((res) => res.data);
};

export const relatedMovementToCustomer = ({
  customerId,
  movementId,
}: {
  customerId: string;
  movementId: string;
}) => {
  return api
    .post(`movements/${movementId}/customer`, { customerId })
    .then((res) => res.data);
};

export const findAllClientsFilter = ({
  filter,
  limit,
}: {
  limit: number;
  filter: string;
}) => {
  return api
    .get("customers/simple", {
      params: {
        limit,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findCnpj = (cnpj: string) => {
  return api.get<ConsultCnpj>(`companys/cnpj/${cnpj}`).then((res) => res.data);
};

export const findCep = (cep: string) => {
  return api.get<ConsultCep>(`ceps/${cep}`).then((res) => res.data);
};

export const createPlan = async (data) => {
  return api.post("plans", data).then((res) => res.data);
};

export const updatePlan = async (
  id: number,
  data: {
    name: string;
    description: string;
    companyId: string;
  }
) => {
  return api.put(`plans/${id}`, data).then((res) => res.data);
};

export const addFeeToPlan = async (id: number, data: Partial<Fee>) => {
  return api.post(`plans/${id}/fee`, data).then((res) => res.data);
};

export const copyPlan = async (data: {
  name: string;
  description: string;
  companyId: string;
  copyId: number;
}) => {
  return api.post(`plans/copy`, data).then((res) => res.data);
};

export const findPlan = async (id: number) => {
  return api.get<Plan>(`plans/${id}`).then((res) => res.data);
};

export const updateFee = async (id: string, data: Partial<Fee>) => {
  return api.put<Plan>(`plans/fee/${id}`, data).then((res) => res.data);
};

export const deletePlan = async (id: number) => {
  return api.delete(`plans/${id}`).then((res) => res.data);
};

export const findAllPlans = async ({
  limit,
  page,
  filter,
  companyId,
}: {
  limit: number;
  page: number;
  filter: string;
  companyId?: string;
}) => {
  return api
    .get<{ registers: Plan[] }>("plans", {
      params: {
        limit,
        companyId,
        page,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findAllWithdrawers = async ({
  limit,
  page,
  filter,
  customerId,
  companyId,
  initialDate,
  finalDate,
  ...rest
}: {
  limit: number;
  page: number;
  status: string;
  filter: string;
  customerId?: string;
  companyId?: string;
  initialDate?: string;
  finalDate?: string;
}) => {
  return api
    .post<{ registers: Withdrawal[] }>("withdrawals/list", {
      limit,
      filter,
      page,
      ...rest,
      customerId,
      companyId,
      initialDate,
      finalDate,
    })
    .then((res) => res.data);
};

export const updateWithdrawel = (
  id: string,
  data: {
    amount: number;
  }
) => {
  return api.put<Withdrawal>(`withdrawals/${id}`, data).then((res) => res.data);
};

export const validateQRCode = async (qrcode: string) => {
  return api
    .post<ShowQrCode>("withdrawals/validate", { qrcode })
    .then((res) => res.data);
};

export const updateWithdrawelNext = (
  id: string,
  data: {
    status: string;
    image?: string;
    manualRelease?: boolean;
  }
) => {
  return api
    .put<{ successWithdraw: boolean }>(`withdrawals/${id}/next`, data)
    .then((res) => res.data);
};

export const findWithdrawel = (id: string) => {
  return api.get<Withdrawal>(`withdrawals/${id}`).then((res) => res.data);
};

export const findAllCharges = (
  filter: {
    limit: number;
    customerId: string;
    page: number;
    maquinetaId?: string;
    filter: string;
  },
  exportPdf = false
) => {
  return api
    .post<Orders>(
      "charges/all",
      { ...filter, exportPdf },
      {
        responseType: exportPdf ? "blob" : "json",
      }
    )
    .then((res) => res.data);
};

export const findAllMovements = (
  filter: {
    limit: number;
    customerId: string;
    page: number;
    maquinetaId?: string;
    filter: string;
    onlyCharges?: boolean;
    exportCsv?: boolean;
  },
  exportPdf = false
) => {
  return api
    .post<Orders>(
      "movements/all",
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: filter.exportCsv || exportPdf ? "blob" : "json",
      }
    )
    .then((res) => res.data);
};

export const findAllMovementsToAntecipate = (
  filter: {
    limit: number;
    customerId: string;
    page: number;
    maquinetaId?: string;
    filter: string;
    onlyCharges?: boolean;
    exportCsv?: boolean;
  },
  exportPdf = false
) => {
  return api
    .post<Orders>(
      "movements/antecipate",
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: filter.exportCsv || exportPdf ? "blob" : "json",
      }
    )
    .then((res) => res.data);
};

export const findAllBalancesPending = (params: {
  limit: number;
  customerId?: string;
  page: number;
  filter: string;
}) => {
  return api
    .get<{
      pages: number;
      registers: any[];
    }>("balances-pending", {
      params,
    })
    .then((res) => res.data);
};

export const findAllBalances = (params: {
  limit: number;
  customerId?: string;
  page: number;
  filter: string;
  exportCsv?: boolean;
}) => {
  return api
    .post<{
      pages: number;
      registers: any[];
    }>("balances/all", params, {
      responseType: params.exportCsv ? "blob" : "json",
    })
    .then((res) => res.data);
};

export const findAllCompanys = (params: {
  limit: number;
  customerId?: string;
  companyId?: string;
  page: number;
  filter: string;
}) => {
  return api
    .get<{
      pages: number;
      registers: any[];
    }>("companys", {
      params,
    })
    .then((res) => res.data);
};

export const findAllIntegrations = ({
  limit,
  page,
  companyId,
  filter,
}: {
  limit: number;
  companyId: string;
  page: number;
  filter: string;
}) => {
  return api
    .get<{
      registers: any[];
    }>("integrations", {
      params: { filter, limit, page, companyId },
    })
    .then((res) => res.data);
};

export const findAllIntegrationsLastData = (
  id: string,
  {
    limit,
    page,
    companyId,
    filter,
  }: {
    limit: number;
    companyId: string;
    page: number;
    filter: string;
  }
) => {
  return api
    .get<{
      registers: any[];
    }>(`integrations/${id}/last-data`, {
      params: { filter, limit, page, companyId },
    })
    .then((res) => res.data);
};

export const changeStatusIntegrations = ({
  id,
  status,
}: {
  id: string;
  status: boolean;
}) => {
  return api
    .put<Orders>(`integrations/${id}/status`, { status })
    .then((res) => res.data);
};

export const changeStatusCompanys = ({
  id,
  status,
}: {
  id: string;
  status: number;
}) => {
  return api
    .put<Orders>(`companys/${id}/status`, { status })
    .then((res) => res.data);
};

export const activeFeatureIntegration = async ({
  id,
  type,
}: {
  id: string;
  type: string;
}) => {
  return api.post(`integrations/${id}/feature/${type}`).then((res) => res.data);
};

export const activateWebhook = async ({
  id,
  type,
}: {
  id: string;
  type: string;
}) => {
  return api
    .post(`integrations/${id}/webhook`, { type })
    .then((res) => res.data);
};

export const removeFeatureIntegration = async ({
  id,
  type,
}: {
  id: string;
  type: string;
}) => {
  return api
    .delete(`integrations/${id}/feature/${type}`)
    .then((res) => res.data);
};

export const sendMailToCompanys = ({
  body,
  subject,
  companyId,
  customerId,
}: {
  body: string;
  subject: string;
  companyId: string;
  customerId: string;
}) => {
  return api
    .post<Orders>(`companys/mail-send`, {
      body,
      subject,
      companyId,
      customerId,
    })
    .then((res) => res.data);
};

export const findAllTerminals = (filters: {
  limit: number;
  page: number;
  filter: string;
  orderBy: { id: string; desc: boolean }[];
}) => {
  return api
    .get<{ total: number; registers: any[] }>("maquinetas", {
      params: filters,
    })
    .then((res) => res.data);
};

export const findTerminalsApp = (filters: {
  limit: number;
  page: number;
  filter: string;
  orderBy: { id: string; desc: boolean }[];
}) => {
  return api
    .get<{ total: number; registers: any[] }>("terminals", {
      params: filters,
    })
    .then((res) => res.data);
};

export const findAllWallets = async (filters: {
  limit: number;
  page: number;
  filter: string;
}) => {
  return api
    .get<{ total: number; registers: any[] }>("wallets", {
      params: filters,
    })
    .then((res) => res.data);
};

export const updateWallet = async (
  id: string,
  data: {
    status: WalletStatus;
  }
) => {
  return api
    .put<{ total: number; registers: any[] }>(`wallets/${id}`, {
      ...data,
    })
    .then((res) => res.data);
};

export const createWallet = async (data: {
  amount: number;
  customerId: string;
  keyPix?: string;
  type: "QRES" | "DICT";
  description: string;
}) => {
  return api.post(`wallets`, data).then((res) => res.data);
};

export const deleteTerminal = (id: string) => {
  return api.delete(`maquinetas/${id}`).then((res) => res.data);
};

export const registerTerminalToCustomer = (data: {
  maquinetaId: string;
  customerId: string;
  companyId?: string;
}) => {
  return api.post(`maquinetas/register`, data).then((res) => res.data);
};

export const changeManyTerminals = (data: {
  maquinetas: string[];
  customerId: string;
}) => {
  return api.post(`maquinetas/changeMany`, data).then((res) => res.data);
};

export const createMaquineta = (data: {
  name: string;
  number: string;
  customerId: string;
  companyId: string;
  terminalId: string;
}) => {
  return api.post(`maquinetas`, data).then((res) => res.data);
};

export const createTerminal = async (data: {
  name: string;
  email: string;
  customerId: string;
  companyId: string;
  document: string;
}) => {
  return api.post(`terminals`, data).then((res) => res.data);
};

export const editMaquineta = async (
  id: string,
  data: {
    name: string;
    number: string;
    terminalId: string;
  }
) => {
  return api.put(`maquinetas/${id}`, data).then((res) => res.data);
};

export const createCompany = (data: {
  mainCustomerId: string;
  name: string;
  document: string;
}) => {
  return api.post(`companys`, data).then((res) => res.data);
};
export const editCompany = (
  id: string,
  data: { mainCustomerId: string; name: string; document: string }
) => {
  return api.put(`companys/${id}`, data).then((res) => res.data);
};
export const changeStatusCustomer = (id: string, status: number) => {
  return api
    .post<{ success: boolean }>(`customers/change/${id}`, { status })
    .then((res) => res.data);
};

export const deleteCustomerService = (id: string) => {
  return api
    .delete<{
      success: boolean;
    }>(`customers/${id}`)
    .then((res) => res.data);
};

export const linkMainCustumer = (
  customerId: string,
  mainCustomerId: string
) => {
  return api
    .post<{
      success: boolean;
    }>(`customers/${customerId}/main`, { mainCustomerId })
    .then((res) => res.data);
};

export const linkCompanyCustumer = (customerId: string, companyId: string) => {
  return api
    .post<{
      success: boolean;
    }>(`customers/${customerId}/company`, { companyId })
    .then((res) => res.data);
};

export const removeLinkMainCustumer = (customerId: string) => {
  return api
    .delete<{
      success: boolean;
    }>(`customers/${customerId}/main`)
    .then((res) => res.data);
};
export const removeLinkCompany = (customerId: string) => {
  return api
    .delete<{
      success: boolean;
    }>(`customers/${customerId}/company`)
    .then((res) => res.data);
};

export const findCustomerLocks = (id: string) => {
  return api
    .get<BalancesPending>(`customers/${id}/locks`)
    .then((res) => res.data);
};

export const findCustomer = (id: string) => {
  return api.get<CustomerResponse>(`customers/${id}`).then((res) => res.data);
};

export const freezeCash = (
  customerId: string,
  data: {
    type: FreezeMethods;
    amount: number;
    tax: number;
    effective: boolean;
  }
) => {
  return api
    .post(`customers/${customerId}/freeze`, data)
    .then((res) => res.data);
};

export const createAccount = (
  customerId: string,
  data: {
    bankIspb: string;
    agency: string;
    account: string;
    pix?: string;
    type: "DICT" | "MANU";
  }
) => {
  return api.post(`accounts`, { ...data, customerId }).then((res) => res.data);
};

export const changePasswordCustomer = (
  customerId: string,
  password: string,
  currentPassword?: string
) => {
  return api
    .put(`customers/${customerId}/changePassword`, {
      password,
      currentPassword,
    })
    .then((res) => res.data);
};

export const blockAmount = (customerId: string, data: any) => {
  return api
    .post(`balancesLocks`, { ...data, customerId })
    .then((res) => res.data);
};

export const changeStatusLock = (id: string, locked: boolean) => {
  return api.put(`balancesLocks/${id}`, { locked }).then((res) => res.data);
};

export const lockFixed = (customerId: string, amount: number) => {
  return api
    .post(`customers/${customerId}/blockAmount`, { amount })
    .then((res) => res.data);
};

export const dashboard = (filter: any) => {
  return api
    .get<DashboardResponse>(`dashboard`, { params: filter })
    .then((res) => res.data);
};

export const dashboardCustomer = (filter: any) => {
  return api
    .get<DashboardResponse>(`dashboard/customer`, { params: filter })
    .then((res) => res.data);
};

export const updateAccount = (
  customerId: string,
  id: string,
  data: {
    bankIspb: string;
    agency: string;
    account: string;
    pix?: string;
    type: "DICT" | "MANU";
    document: string;
  }
) => {
  return api
    .put(`accounts/${id}`, { ...data, customerId })
    .then((res) => res.data);
};

export const deleteCustomerAccount = (id: string) => {
  return api.delete(`accounts/${id}`).then((res) => res.data);
};

export const createIntegration = (data: {
  name: string;
  user: string;
  type: string;
  token: string;
  active: boolean;
  clientId: string;
  clientSecret: string;
  pixKey?: string;
  endpoint?: string;
  numeroContrato?: string;
}) => {
  return api.post(`integrations`, data).then((res) => res.data);
};

export const createIntegrationToCompany = ({
  integrationId,
  companyId,
}: {
  integrationId: string;
  companyId: string;
}) => {
  return api
    .post(`integrations/${integrationId}/company/${companyId}`)
    .then((res) => res.data);
};

export const removeIntegrationFromCompany = ({
  integrationId,
  companyId,
}: {
  integrationId: string;
  companyId: string;
}) => {
  return api
    .delete(`integrations/${integrationId}/company/${companyId}`)
    .then((res) => res.data);
};

export const getAccounts = ({ page = 0, limit = 10, ...params }: any) => {
  return api
    .get(`accounts`, { params: { ...params, page, limit } })
    .then((res) => res.data);
};

export const syncPayments = (id: string) => {
  return api.put(`integrations/${id}/sync-payments`).then((res) => res.data);
};

export const generatePaymentsForCharge = (
  id: string,
  type: "pix" | "billet"
) => {
  return api.post<Charge>(`charges/${id}/${type}`).then((res) => res.data);
};

export const cancelBillet = (chargeId: string, billetId: string) => {
  return api
    .delete(`charges/${chargeId}/billet/${billetId}`)
    .then((res) => res.data);
};

export const estornarPix = (chargeId: string) => {
  return api.put(`charges/${chargeId}/pix/refund`).then((res) => res.data);
};
export const estornarCreditCard = (chargeId: string, amount: number) => {
  return api
    .put(`charges/${chargeId}/credit-card/refund`, { amount })
    .then((res) => res.data);
};

export const removeIntegration = async (id: string) => {
  return api.delete<Orders>(`integrations/${id}`).then((res) => res.data);
};

export const fetchBalanceIntegration = async (id: string) => {
  return api
    .get<{
      balance: number;
      balanceBlocked: number;
    }>(`integrations/${id}/balance`)
    .then((res) => res.data);
};

export const saveFeatures = async (
  id: string,
  token: string,
  data: CustomerFeatures
) => {
  return api
    .post(`customers/${id}/features`, data, {
      headers: {
        "x-security-code": token,
      },
    })
    .then((res) => res.data);
};

export const editCustomerConfigurations = async (
  id: string,
  token: string,
  data: CustomerConfigurations
) => {
  return api
    .post(`customers/${id}/configurations`, data, {
      headers: {
        "x-security-code": token,
      },
    })
    .then((res) => res.data);
};

export const automaticWithdraw = async (
  id: string,
  token: string,
  data: {
    percent: number;
    accountId: string;
    maxAutomaticWithdrawal: number;
    active: boolean;
    type: "DAILY" | "WEEKLY" | "MONTHLY" | "HOURLY";
    period: number[];
  }
) => {
  return api
    .post(`customers/${id}/automatic-transfer`, data, {
      headers: {
        "x-security-code": token,
      },
    })
    .then((res) => res.data);
};

export const createInsurance = async (data: {
  customerId: string;
  holderId: string;
  startDate: string;
  modelId: string;
  endDate: string;
}) => {
  return api.post(`insurances`, data).then((res) => res.data);
};

export const createHolder = async (data: CreateHolderForm) => {
  return api.post(`insurances/holders`, data).then((res) => res.data);
};

export const createInsuranceModel = async (data: {
  companyId: string;
  name: string;
  pricePerDay: number;
  price: number;
  token: string;
  customerReceiverId: string;
}) => {
  return api.post(`insurances/models`, data).then((res) => res.data);
};

export const findAllHolders = ({
  limit = 20,
  customerId,
  filter = "",
  page = 0,
  companyId,
}: {
  customerId: string;
  limit?: number;
  page?: number;
  filter?: string;
  companyId?: string;
}) => {
  return api
    .get<CustomerListResponse>("insurances/holders", {
      params: {
        limit,
        page,
        customerId,
        companyId,
        filter,
      },
    })
    .then((res) => res.data);
};

export const findAllInsurancesModels = ({
  limit = 20,
  customerId,
  filter = "",
  page = 0,
  companyId,
}: {
  customerId: string;
  limit?: number;
  page?: number;
  filter?: string;
  companyId?: string;
}) => {
  return api
    .get<CustomerListResponse>("insurances/models/admin", {
      params: {
        limit,
        page,
        customerId,
        companyId,
        filter,
      },
    })
    .then((res) => res.data);
};
export const cancelModel = async ({ id }: { id: string }) => {
  return api.delete(`insurances/models/${id}`).then((res) => res.data);
};

export const findAllInsurances = ({
  limit,
  page,
  companyId,
  filter,
}: {
  limit: number;
  companyId: string;
  page: number;
  filter: string;
}) => {
  return api
    .get<Orders>("insurances", {
      params: { filter, limit, page, companyId },
    })
    .then((res) => res.data);
};
export const cancelInsurance = async ({ id }: { id: string }) => {
  return api.delete(`insurances/${id}`).then((res) => res.data);
};

export const createCard = async (data: {
  customerId: string;
  finalNumber: string;
  description: string;
  limit: number;
  status: boolean;
  invoiceClosingDay: number;
  automaticRenewal: boolean;
  fillAmount: boolean;
}) => {
  return api
    .post<{
      pages: number;
      registers: any[];
    }>(`cards`, data)
    .then((res) => res.data);
};

export const createCardFill = async (
  id: string,
  data: {
    amount: number;
  }
) => {
  return api
    .post<{
      pages: number;
      registers: any[];
    }>(`cards/${id}/fill`, data)
    .then((res) => res.data);
};

export const cancelCard = async (id: string) => {
  return api.delete(`cards/${id}`).then((res) => res.data);
};

export const createMovementCard = async (data: {
  cardId: string;
  amount: number;
  itens: {
    amount: number;
    description: string;
  }[];
}) => {
  return api
    .post<{
      pages: number;
      registers: any[];
    }>(`cards/movements`, data)
    .then((res) => res.data);
};

export const findCards = async (
  customerId: string,
  { limit = 10, page = 0 }: { limit: number; page: number } | undefined
) => {
  return api
    .post<CardList>(`cards/all`, { customerId, limit, page })
    .then((res) => res.data);
};
export const findAllMovementsCard = ({
  limit = 20,
  filter = "",
  page = 0,
  companyId,
  customerId,
  cardId,
}: {
  limit?: number;
  page?: number;
  filter?: string;
  companyId?: string;
  customerId?: string;
  cardId: string;
}) => {
  return api
    .get<any>(`cards/${cardId}/movements`, {
      params: {
        limit: +limit,
        page: +page,
        companyId,
        customerId,
        filter,
      },
    })
    .then((res) => res.data);
};

export const getRecurrence = async (id: string) => {
  return api
    .get<RecurrenceResponse>(`recurrences/${id}`)
    .then((res) => res.data);
};

export const findAllRecurrences = async (
  filter: {
    limit: number;
    customerId: string;
    page: number;
    maquinetaId?: string;
    filter: string;
  },
  exportPdf = false
) => {
  return api
    .post(
      "recurrences/all",
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: exportPdf ? "blob" : "json",
      }
    )
    .then((res) => res.data);
};

export const addClientToRecurrence = async (
  recurrenceId: string,
  data: { clientId: string }
) => {
  return api
    .post(`recurrences/${recurrenceId}/add`, data)
    .then((res) => res.data);
};

export const findAllClientsRecurrences = (
  filter: {
    limit: number;
    page: number;
    maquinetaId?: string;
    filter: string;
  },
  exportPdf = false
) => {
  return api
    .post(
      "clients/all",
      {
        ...filter,
        exportPdf,
      },
      {
        responseType: exportPdf ? "blob" : "json",
      }
    )
    .then((res) => res.data);
};

export const findClientById = async (id: string) => {
  return api
    .get<{
      id: string;
      name: string;
      document: string;
      email: string;
      phone: string;
      customer: {
        id: string;
        name: string;
      };
    }>(`/clients/${id}`)
    .then((res) => res.data);
};

export const createClient = async (data: {
  email: string;
  phone: string;
  name: string;
  document: string;
  customerId: string;
}) => {
  return api.post(`clients`, data).then((res) => res.data);
};

export const editClient = async (
  id: string,
  data: {
    email: string;
    phone: string;
    name: string;
    document: string;
    customerId: string;
  }
) => {
  return api.put(`clients/${id}`, data).then((res) => res.data);
};

export const realizarLevantamentoApi = async (data: {
  recurrencyId: string;
  amount: number;
}) => {
  return api
    .post(`recurrences/${data.recurrencyId}/withdraw`, data)
    .then((res) => res.data);
};

export const atribuirClientOnWallet = async (data: {
  walletId: string;
  clientId: string;
}) => {
  return api
    .post(`wallets/${data.walletId}/client/${data.clientId}`, data)
    .then((res) => res.data);
};

export const createRecurrency = async (data: {
  amount: string;
  description: string;
  initDate: string;
  period: string;
  endDate: string;
  customerId: string;
}) => {
  return api.post(`recurrences`, data).then((res) => res.data);
};

export const findClientPayments = async (id: string) => {
  return api
    .get<RecurrencyClientPayments>(`recurrences/payments/${id}`)
    .then((res) => res.data);
};

export const createNewRecurrencePaymentRequest = async ({
  id,
  withoutCharge,
}: {
  id: string;
  withoutCharge: boolean;
}) => {
  return api
    .post(`recurrences/payments/${id}/charge`, { withoutCharge })
    .then((res) => res.data);
};
export const sendMailRecurrencePaymentRequest = async (id: string) => {
  return api.post(`recurrences/generated/${id}/mail`).then((res) => res.data);
};
export const doneRecurrencePayment = async (id: string) => {
  return api.put(`recurrences/generated/${id}/done`).then((res) => res.data);
};

export const cancelRecurrencePayment = async (id: string) => {
  return api.delete(`recurrences/generated/${id}`).then((res) => res.data);
};

export const newChargeForGeneratedRecurrence = async (id: string) => {
  return api.put(`recurrences/generated/${id}`).then((res) => res.data);
};

export const linkWalletToRecurrencyApi = async ({
  id,
  walletId,
}: {
  id: string;
  walletId: string;
}) => {
  return api
    .put(`recurrences/${id}/wallet/${walletId}`)
    .then((res) => res.data);
};

export const validateBillet = async (billet: string) => {
  return api
    .post<ShowQrCode>("withdrawals/validate-billet", { billet })
    .then((res) => res.data);
};
export const validateUserCode = async (code: string) => {
  return api
    .post<{ id: string }>("users/validateCode", { code })
    .then((res) => res.data);
};

export const consultPendencysUser = async () => {
  return api
    .get<{ has2fa: boolean; hasCode: boolean }>("users/pendencys")
    .then((res) => res.data);
};

export const registerCode = async (data: {
  code?: string;
  newCode: string;
}) => {
  return api
    .post<{
      success: true;
    }>("users/code", data)
    .then((res) => res.data);
};

export default api;
