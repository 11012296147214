import { IconButton, Button, ButtonGroup, Tooltip } from "@chakra-ui/react";
import AlertDeleteAccount from "components/AlertDeleteAccount";
import TableData from "components/TableData";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { FaPen } from "react-icons/fa";
import { Column } from "react-table";
import CreateAccountCustomer, {
  CreateAccountCustomerParams,
} from "./RegisterAccount";

interface Props {
  accounts: any[];
  customerId: string;
  refetch: () => void;
}

const Accounts: React.FC<Props> = ({ accounts, refetch, customerId }) => {
  const [addAccount, setAddAccount] = useState<
    boolean | CreateAccountCustomerParams
  >(false);

  const columnsAccounts = useMemo(
    () =>
      [
        {
          Header: "Banco",
          accessor: "bankIspb",
          align: "right",
        },
        {
          Header: "Agência",
          accessor: "agency",
          align: "right",
        },
        {
          Header: "Conta",
          align: "right",
          accessor: "account",
        },
        {
          Header: "Chave Pix",
          align: "right",
          accessor: "pix",
          Cell: ({ value }) => {
            return value || "---";
          },
        },

        {
          accessor: "createdAt",
          Header: "Data",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },

        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const handleEditAccount = (row: any) => {
    setAddAccount({
      ...row,
      document: row.accountHolderDocument,
      name: row.accountHolder,
    });
  };

  function handleAddAccount(): void {
    setAddAccount(true);
  }

  return (
    <div>
      {addAccount && (
        <CreateAccountCustomer
          isOpen={!!addAccount}
          data={addAccount === true ? undefined : addAccount}
          customerId={customerId}
          onClose={() => {
            refetch();
            setAddAccount(false);
          }}
        />
      )}
      <TableData
        title="Contas Cadastradas"
        pageEnable={true}
        actions={(row: any) => (
          <ButtonGroup>
            <Tooltip label="Editar">
              <IconButton
                onClick={() => {
                  handleEditAccount(row);
                }}
                variant="outline"
                rounded="sm"
                size="sm"
                aria-label="Editar Conta"
                icon={<FaPen />}
              />
            </Tooltip>
            <AlertDeleteAccount id={row.id} refetch={refetch} />
          </ButtonGroup>
        )}
        columnsData={columnsAccounts}
        tableData={accounts || []}
        rightActions={
          <Button
            onClick={() => handleAddAccount()}
            variant="outline"
            size="sm"
            colorScheme="gray"
          >
            Adicionar Conta
          </Button>
        }
      />
    </div>
  );
};

export default Accounts;
