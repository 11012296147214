import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  Tooltip,
  useToast,
  CheckboxGroup,
  Checkbox,
  Divider,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { addClientEye, findAllEyeEvents, findAllPayTypes } from "./api";
import CustomButton from "components/CustomButton";
import { useForm } from "react-hook-form";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { findAllClients } from "services/api.service";

export interface Company {
  id: string;
  name: string;
  document: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntegrationResponse {
  id: string;
  name: string;
  type: string;
  user: string;
  createdAt: Date;
  identifierUrl: string;
  active: boolean;
}

const AddClientEye: React.FC<{
  integrationId: string;
  eyeIntegrationId: number;
  refetch: () => void;
  editData?: {
    id: number;
    customer: SelectOptionType;
    event: SelectOptionType;
    paymentTypes: Array<string | number>;
  };
}> = ({ eyeIntegrationId, integrationId, refetch, editData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { data: paymentTypes } = useQuery(
    ["findAllPayTypes", integrationId],
    () => findAllPayTypes({ integrationId, limit: 40, page: 0 }),
    {
      enabled: isOpen,
    }
  );

  const { control, setValue, handleSubmit } = useForm<{
    customer: SelectOptionType;
    event: SelectOptionType;
    paymentTypes: Array<string | number>;
  }>();

  const sendClientEye = async (data) => {
    addClientEye({
      customerId: data.customer.value,
      eventId: data.event?.value,
      eyeIntegrationId,
      payTypes: data.paymentTypes,
      id: editData?.id,
    })
      .then(() => {
        toast({
          title: "Cliente adicionado com sucesso",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        onClose();
        refetch();
      })
      .catch((err) => {
        toast({
          title: "Erro ao adicionar cliente",
          description: err.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (isOpen) {
      setValue("customer", editData?.customer);
      setValue("event", editData?.event);
      setValue("paymentTypes", editData?.paymentTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {
        <Tooltip label="Exibir clientes">
          <CustomButton onClick={onOpen}>
            {editData ? "Editar" : "Adicionar Cliente"}
          </CustomButton>
        </Tooltip>
      }
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Adicionar</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} spacing={3} alignItems="flex-start">
                <FormRemoteSelectInput
                  control={control}
                  label="Cliente Recebedor"
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value }).then((retorno) => {
                      if (retorno?.registers?.length > 0) {
                        cb(
                          retorno.registers?.map((d: any) => ({
                            label: d.name,
                            value: d.id,
                          }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="customer"
                />
                <FormRemoteSelectInput
                  control={control}
                  label="Filtrar por evento"
                  loadDataFn={({ value, cb }) =>
                    findAllEyeEvents({
                      integrationId,
                      limit: 40,
                      page: 0,
                    }).then((retorno) => {
                      if (retorno.length > 0) {
                        cb(
                          retorno
                            ?.filter((item) => item.status === 4)
                            .map((d: any) => ({
                              label: d.name,
                              value: d.id,
                            }))
                        );
                      } else {
                        cb([]);
                      }
                    })
                  }
                  name="event"
                />
                <Divider />
                <Text>Formas de Pagamento</Text>
                <CheckboxGroup
                  colorScheme="green"
                  onChange={(value) => setValue("paymentTypes", value)}
                  defaultValue={editData?.paymentTypes}
                >
                  {paymentTypes?.map((item) => {
                    return <Checkbox value={item.id}>{item.name}</Checkbox>;
                  })}
                </CheckboxGroup>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                colorScheme="blue"
                onClick={handleSubmit(sendClientEye)}
              >
                Salvar
              </CustomButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AddClientEye;
