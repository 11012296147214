import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";

import { GrIntegration } from "react-icons/gr";

import { FaReceipt, FaUsersCog } from "react-icons/fa";
// Admin Imports
import Customers from "views/admin/customers";
import MainDashboard from "views/admin/default";
import Orders from "views/admin/orders";
import Users from "views/admin/users";

// Auth Imports
import { UserPermissions } from "contexts/useAuth";
import { BsPhoneFlip, BsSafe } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import { GoCalendar, GoPeople } from "react-icons/go";
import {
  IoCashOutline,
  IoPeopleCircle,
  IoPeopleOutline,
} from "react-icons/io5";
import {
  PiHourglassMediumThin,
  PiInvoiceLight,
  PiPercent,
} from "react-icons/pi";
import { RiBankFill, RiDashboardHorizontalLine } from "react-icons/ri";
import { TbAdjustmentsHorizontal, TbShoppingCartDollar } from "react-icons/tb";
import { VscListSelection } from "react-icons/vsc";
import Balances from "views/admin/balances/balances";
import BalancesPending from "views/admin/balances/balances-pending";
import Charges from "views/admin/charges";
import Charge from "views/admin/charges/Charge";
import Companys from "views/admin/companys";
import Customer from "views/admin/customers/customer";
import Settings from "views/admin/customers/settings";
import Insurances from "views/admin/insurances";
import InsurancesModels from "views/admin/insurances/models";
import Integrations from "views/admin/integrations";
import Maquinetas from "views/admin/maquinetas";
import NoPermission from "views/admin/no-perm";
import Movement from "views/admin/orders/Movement";
import Plans from "views/admin/taxes";
import UserGroups from "views/admin/usergroups";
import WalletsPage from "views/admin/wallets";
import Withdrawals from "views/admin/withdrawals";
import SignIn from "views/auth/signIn";
import Privacy from "views/auth/signIn/privacy";
import { MdOutlineNumbers } from "react-icons/md";

import { BsPeople } from "react-icons/bs";
import { PiWallet } from "react-icons/pi";
import Recurrences from "views/admin/recurrences/recurrences";
import Clients from "views/admin/recurrences/clients";
import ShowRecurrence from "views/admin/recurrences/recurrences/ShowRecurrence";
import { getVariable } from "whitelabel";
import Terminais from "views/admin/terminais";
import Antecipations from "views/admin/orders/antecipations";
import LastNotifications from "views/admin/integrations/lastNotifications";

const routes: RoutesType[] = [
  {
    name: "Dashboard",
    layout: "/admin",
    perm: UserPermissions.DASHBOARD_READ,
    pathPrevious: "/#/admin/dashboard",
    namePrevious: "Início",
    path: "/dashboard",
    icon: (
      <Icon
        as={RiDashboardHorizontalLine}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: MainDashboard,
  },
  {
    name: "Vendas",
    layout: "/admin",
    perm: UserPermissions.MOVEMENTS_READ,
    icon: (
      <Icon
        as={TbShoppingCartDollar}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/transactions",
    component: Orders,
  },
  {
    name: "Antecipações",
    layout: "/admin",
    perm: UserPermissions.MOVEMENTS_READ,
    icon: <Icon as={GoCalendar} width="20px" height="20px" color="inherit" />,
    path: "/antecipations",
    component: Antecipations,
  },
  {
    name: "Extratos",
    layout: "/admin",
    perm: UserPermissions.BALANCES_READ,
    icon: (
      <Icon as={VscListSelection} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        name: "Listagem",
        layout: "/admin",
        perm: UserPermissions.BALANCES_READ,
        icon: (
          <Icon as={GiMoneyStack} width="20px" height="20px" color="inherit" />
        ),
        path: "/balances",
        component: Balances,
      },
      {
        name: "Pendentes",
        layout: "/admin",
        perm: UserPermissions.BALANCES_READ,
        icon: (
          <Icon
            as={PiHourglassMediumThin}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/balance-pending",
        component: BalancesPending,
      },
    ],
  },
  {
    name: "Cobranças",
    perm: UserPermissions.CHARGES_READ,
    layout: "/admin",
    icon: (
      <Icon as={PiInvoiceLight} width="20px" height="20px" color="inherit" />
    ),
    path: "/charges",
    component: Charges,
  },
  {
    name: "Clientes",
    isAdmin: true,
    perm: UserPermissions.CUSTOMERS_READ,
    layout: "/admin",
    icon: <Icon as={BsPeople} width="20px" height="20px" color="inherit" />,
    path: "/customers",
    component: Customers,
  },
  {
    name: "Empresas",
    isAdmin: true,
    perm: UserPermissions.COMPANYS_READ,
    isMaster: true,
    layout: "/admin",
    icon: <Icon as={RiBankFill} width="20px" height="20px" color="inherit" />,
    path: "/companys",
    component: Companys,
  },
  {
    name: "Recorrências",
    layout: "/admin",
    perm: UserPermissions.BALANCES_READ,
    icon: (
      <Icon as={VscListSelection} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        name: "Listagem",
        layout: "/admin",
        perm: UserPermissions.BALANCES_READ,
        icon: (
          <Icon as={GiMoneyStack} width="20px" height="20px" color="inherit" />
        ),
        path: "/recurrences",
        component: Recurrences,
      },
      {
        name: "Clientes/Promoters",
        layout: "/admin",
        perm: UserPermissions.BALANCES_READ,
        icon: (
          <Icon
            as={PiHourglassMediumThin}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/clients",
        component: Clients,
      },
      {
        name: "Carteiras",
        layout: "/admin",
        isAdmin: true,
        perm: UserPermissions.WALLET_READ,
        icon: <Icon as={PiWallet} width="20px" height="20px" color="inherit" />,
        component: WalletsPage,
        path: "/wallets",
      },
      {
        name: "Consultar Recorrência",
        layout: "/admin",
        hideOnSideBar: true,
        icon: (
          <Icon as={FaReceipt} width="20px" height="20px" color="inherit" />
        ),
        path: "/recurrence/:id",
        component: ShowRecurrence,
      },
    ],
  },
  {
    name: "Integrações",
    isAdmin: true,
    isMaster: true,
    layout: "/admin",
    perm: UserPermissions.INTEGRATIONS_READ,
    icon: (
      <Icon as={GrIntegration} width="20px" height="20px" color="inherit" />
    ),
    path: "/integrations",
    component: Integrations,
  },
  {
    name: "Integrações Dados",
    isAdmin: true,
    isMaster: true,
    layout: "/admin",
    perm: UserPermissions.INTEGRATIONS_READ,
    icon: (
      <Icon as={GrIntegration} width="20px" height="20px" color="inherit" />
    ),
    path: "/integration/:id/last",
    component: LastNotifications,
    hideOnSideBar: true,
  },
  {
    name: "Movimento",
    layout: "/admin",
    icon: (
      <Icon as={GrIntegration} width="20px" height="20px" color="inherit" />
    ),
    path: "/transactions/:id",
    hideOnSideBar: true,
    component: Orders,
  },
  {
    name: "Cobrança",
    layout: "/admin",
    icon: (
      <Icon as={GrIntegration} width="20px" height="20px" color="inherit" />
    ),
    path: "/charge/:id",
    hideOnSideBar: true,
    component: Charge,
  },
  {
    name: "Saques",
    layout: "/admin",
    perm: UserPermissions.WITHDRAWALS_READ,
    icon: (
      <Icon as={IoCashOutline} width="20px" height="20px" color="inherit" />
    ),
    path: "/saques",
    component: Withdrawals,
  },

  {
    name: "Venda",
    layout: "/admin",
    icon: (
      <Icon as={IoCashOutline} width="20px" height="20px" color="inherit" />
    ),
    path: "/movement/:id",
    pathPrevious: "/#/admin/transactions",
    namePrevious: "Vendas",
    component: Movement,
    hideOnSideBar: true,
  },
  {
    name: "Terminais",
    icon: (
      <Icon as={MdOutlineNumbers} width="20px" height="20px" color="inherit" />
    ),
    layout: "/admin",
    children: [
      {
        name: getVariable("name"),
        layout: "/admin",
        icon: (
          <Icon as={BsPhoneFlip} width="20px" height="20px" color="inherit" />
        ),
        path: "/terminais",
        perm: UserPermissions.TERMINAL_READ,
        component: Terminais,
      },
      {
        name: "POS",
        layout: "/admin",
        icon: (
          <Icon
            as={MdOutlineNumbers}
            width="20px"
            height="20px"
            color="inherit"
          />
        ),
        path: "/maquinetas",
        perm: UserPermissions.MAQUINETAS_READ,
        component: Maquinetas,
      },
    ],
  },
  {
    name: "Cliente",
    layout: "/admin",
    icon: (
      <Icon as={IoPeopleCircle} width="20px" height="20px" color="inherit" />
    ),
    path: "/customer/:id",
    pathPrevious: "/#/admin/customers",
    namePrevious: "Clientes",
    component: Customer,
    hideOnSideBar: true,
  },
  {
    name: "Configurações",
    layout: "/admin",
    icon: (
      <Icon
        as={TbAdjustmentsHorizontal}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/settings",
    pathPrevious: "/#/dashboard",
    namePrevious: "Início",
    component: Settings,
  },
  {
    name: "Planos",
    layout: "/admin",
    perm: UserPermissions.PLAN_READ,
    icon: <Icon as={PiPercent} width="20px" height="20px" color="inherit" />,
    component: Plans,
    path: "/plans",
  },

  {
    name: "Modelos",
    layout: "/admin",
    hideOnSideBar: true,
    path: "/insurances/models",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: InsurancesModels,
  },
  {
    name: "Sem permissão",
    layout: "/admin",
    hideOnSideBar: true,
    path: "/no-permission",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: NoPermission,
  },
  {
    name: "Seguros",
    layout: "/admin",
    isAdmin: true,
    perm: UserPermissions.INSURANCES_READ,
    icon: <Icon as={BsSafe} width="20px" height="20px" color="inherit" />,
    path: "/insurances",
    component: Insurances,
  },
  {
    name: "Usuários",
    layout: "/admin",
    isAdmin: true,
    perm: UserPermissions.USERS_READ,
    icon: (
      <Icon as={IoPeopleOutline} width="20px" height="20px" color="inherit" />
    ),
    children: [
      {
        name: "Listagem",
        layout: "/admin",
        isAdmin: true,
        perm: UserPermissions.USERS_READ,
        icon: <Icon as={GoPeople} width="20px" height="20px" color="inherit" />,
        path: "/users",
        component: Users,
      },
      {
        name: "Grupos",
        layout: "/admin",
        isAdmin: true,
        perm: UserPermissions.USERS_READ,
        icon: (
          <Icon as={FaUsersCog} width="20px" height="20px" color="inherit" />
        ),
        path: "/groupsusers",
        component: UserGroups,
      },
    ],
  },
  {
    name: "Entrar",
    layout: "/auth",
    hideOnSideBar: true,
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignIn,
  },
  {
    name: "Política de Privacidade",
    layout: "/auth",
    hideOnSideBar: true,
    path: "/privacy",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Privacy,
  },
];

export default routes;
