import { Box, ButtonGroup, Heading, Text, VStack } from "@chakra-ui/react";
import Card from "components/card/Card";
import useAuth from "contexts/useAuth";
import ChangePasswordCustomer from "./components/ChangePasswordCustomer";
import Enable2FA from "./Enable2FA";
import EnableSafeCode from "./EnableSafeCode";
import { useQuery } from "@tanstack/react-query";
import { consultPendencysUser } from "services/api.service";

export default function Settings() {
  const { userData } = useAuth();
  const { data } = useQuery(["consultPendencysUser"], () =>
    consultPendencysUser()
  );

  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px={5}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Heading mb={2} fontSize={20}>
          Olá, {userData?.name}
        </Heading>
        <Heading fontSize={20} fontWeight="semibold">
          Configurações
        </Heading>
        <ButtonGroup>
          <VStack align="flex-start">
            <Text fontSize="lg" fontWeight="bold" mt={5}>
              Segurança
            </Text>
            <Text fontSize="sm" color="gray.500">
              Altere sua senha e ative a autenticação de dois fatores.
            </Text>
            <ButtonGroup>
              <ChangePasswordCustomer customerId={userData?.id} />
              {!data?.has2fa && <Enable2FA />}
              {data?.has2fa && (
                <Text mt={2} fontSize="sm" fontWeight="bold" color="gray.700">
                  2FA ativado
                </Text>
              )}
            </ButtonGroup>
          </VStack>
        </ButtonGroup>
        <VStack align="flex-start">
          <Text fontSize="lg" fontWeight="bold" mt={5}>
            Código de Segurança
          </Text>
          <Text fontSize="sm" color="gray.500">
            Ative o código de segurança para proteger sua conta.
          </Text>
          <ButtonGroup>
            <EnableSafeCode isEnabled={!!data?.hasCode} />
          </ButtonGroup>
        </VStack>
      </Card>
    </Box>
  );
}
