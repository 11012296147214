import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  VStack,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import { FaSwatchbook } from "react-icons/fa";
import JSONPretty from "react-json-pretty";

const ShowJsonBody: React.FC<{ json: object }> = ({ json }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Tooltip label="Dados de integração">
        <IconButton
          aria-label="Apertar"
          rounded="md"
          size="sm"
          variant="outline"
          icon={<FaSwatchbook />}
          onClick={onOpen}
        />
      </Tooltip>
      {isOpen && (
        <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Dados Recebidos</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack mb={5} spacing={3}>
                <JSONPretty id="json-pretty" data={json}></JSONPretty>
              </VStack>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ShowJsonBody;
